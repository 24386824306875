import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { faFacebookF, faWeixin } from "@fortawesome/free-brands-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconClose from "@src/app/assets/images/icon-close.svg";
import iconShare from "@src/app/assets/images/icon-share.svg";
import Column from "@src/app/components/Layouts/Column/Column";
import Row from "@src/app/components/Layouts/Row/Row";
import useWindowDimensions from "@src/app/hooks/useWindowDimensions";
import cx from "classnames";
import { ReactElement, useEffect, useState } from "react";

import styles from "./ShareButtons.module.css";

interface WindowWithA2A extends Window {
  a2a?: {
    init: (type: string) => void;
  };
}

type Props = Readonly<{
  expandable?: boolean;
  // isArticleAComic?: boolean; // New prop
}>;

export default function ShareButtons({
  expandable = false,
  // isArticleAComic = false, // Default to false if not provided
}: Props): ReactElement {
  const dimensions = useWindowDimensions();
  const isTablet = dimensions.width > 992;
  const a2aClasses = "a2a_kit a2a_kit_size_32";
  const sendDataToGTM = useGTMDispatch();
  const expandableShare = expandable ? expandable : !isTablet ? true : false;
  const [isExpandableShareOpen, setIsExpandableShareOpen] = useState(false);

  const handleClickGTM = (eventLabel: string) => {
    sendDataToGTM({
      event: "tcCustomEvent",
      eventCategory: "social sharing",
      eventLabel: eventLabel,
      eventAction: "click",
    });
  };

  useEffect(() => {
    const windowWithA2A = window as WindowWithA2A;

    if (windowWithA2A.a2a) {
      windowWithA2A.a2a.init("page");
    }
  }, []);

  return (
    <div
      className={cx(styles.social, expandableShare && styles.expandable)}
      data-testid="social-share"
    >
      <Row isVCenter noGutters>
        <Column className={cx(styles.noGutters, styles.right)}>
          {expandableShare && (
            <button
              className={styles.expandableShare}
              onClick={() => {
                setIsExpandableShareOpen((c) => !c);
              }}
            >
              {isExpandableShareOpen ? (
                <img className={styles.icon} src={iconClose} alt="close" />
              ) : (
                <img className={styles.icon} src={iconShare} alt="share" />
              )}
            </button>
          )}

          <div
            className={cx(styles.addToAnyList, a2aClasses, {
              [styles.active]: isExpandableShareOpen,
            })}
          >
            <a
              href="#share-facebook"
              className="a2a_button_facebook"
              aria-label="Share on Facebook"
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                color="#393939"
                onClick={() => {
                  handleClickGTM("facebook");
                }}
              />
            </a>
            <a
              href="#share-twitter"
              className="a2a_button_twitter"
              aria-label="Share on Twitter"
            >
              <FontAwesomeIcon
                icon={faXTwitter}
                color="#393939"
                onClick={() => {
                  handleClickGTM("twitter");
                }}
              />
            </a>
            <a
              href="#share-wechat"
              className="a2a_button_wechat"
              aria-label="Share on WeChat"
              onClick={() => {
                handleClickGTM("wechat");
              }}
            >
              <FontAwesomeIcon
                icon={faWeixin}
                color="#393939"
                onClick={() => {
                  handleClickGTM("wechat");
                }}
              />
            </a>
            <a
              href="#share-whatsapp"
              className="a2a_button_whatsapp"
              aria-label="Share on WhatsApp"
            >
              <FontAwesomeIcon
                icon={faWhatsapp}
                color="#393939"
                onClick={() => {
                  handleClickGTM("whatsapp");
                }}
              />
            </a>
          </div>
        </Column>
      </Row>
    </div>
  );
}
