import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import getStoryMediaCaption, {
  getStoryMediaAlt,
} from "@src/app/helper/getStoryMediaCaption";
import getStoryMediaElement from "@src/app/helper/getStoryMediaElement";
import { DeepPartial } from "@src/app/types";
import { ArticleCard, Author } from "src/app/types/Article";
import {
  CUERelatedContributorProfile,
  CUETags,
  OSSearchHits,
  PartialCueArticle,
} from "src/app/types/Opensearch";
import getArticleCardDataFromOsSearchHit from "src/app/utils/getArticleCardDataFromSearchHits";

import { processElements, ResolvedCUEElement } from "./elements";

interface OgFields {
  ogTitle: string;
  ogDescription: string;
}

interface SeoFields {
  metaTitle: string;
  metaDescription: string;
  metaRobots: string;
  yoastMeta?: string;
  canonicalUrl?: string;
}

export interface Article {
  id: string;
  heroImgUrl: string;
  heroImgCaption?: string | undefined;
  heroImgAlt?: string | undefined;
  heroImgWidth?: number;
  mainVideoUrl?: string | undefined;
  heroImgHeight?: number;
  authors: Author[];
  translators: string;
  publishedDate: string;
  headline: string;
  standfirst: string;
  bodyElements: ResolvedCUEElement[];
  /** pass origin data to FE to use Annotation */
  mainMedia: ResolvedCUEElement[];
  tags: CUETags[];
  category: string;
  displayType: string;
  recommendedArticles?: ArticleCard[];
  seoFields?: SeoFields;
  ogFields?: OgFields;
  ogImageUrl?: string;
}

export type ArticlePageListing = {
  article?: Article;
  grapeshot?: string | null;
  fr?: string;
  popularThisMonth?: ArticleCard[];
};

export function getArticleFromOSResponse(
  searchHits: (OSSearchHits<PartialCueArticle> | undefined)[],
): Article {
  const article = searchHits[0];
  const context = article?._source?.data?.context;
  const tags = context?.tags || [];
  const sections = context?.sections || [];
  const relatedCUEContributors = context?.relatedContributorProfile || [];
  const elements = context?.elements || [];
  const authors: Author[] = relatedCUEContributors.map(
    (rawAuthor: DeepPartial<CUERelatedContributorProfile>) => {
      const headFields = rawAuthor.content?.headshotImage?.[0]?.content?.fields;
      return {
        url: rawAuthor.content?.urlPath || "/",
        name: rawAuthor.content?.fields?.name || "",
        designation: rawAuthor.content?.fields?.designation || "",
        headshotImageUrl:
          (headFields?.["square_320-caas"] || headFields?.["square_320"])
            ?.url || "",
      };
    },
  );

  const translators = context?.byline?.translator ?? "";
  const id = context?.id ?? "";
  const media = context?.media ?? [];
  const publishedDate = context?.updated ?? "";
  const relatedMedia = media[0]?.content?.fields;
  const storyMedia = getStoryMediaElement(elements);
  const storyMediaCaption = getStoryMediaCaption(elements);
  const storyMediaAlt = getStoryMediaAlt(elements);

  // Assign values to heroImgUrl and heroImgCaption
  const heroImgStoryMedia =
    typeof storyMedia[0] != "string"
      ? (storyMedia[0]?.["original-caas"] ?? storyMedia[0]?.["original"])
      : null;

  const heroImgRelatedMedia =
    relatedMedia?.["original-caas"] ?? relatedMedia?.["original"];

  const heroImgUrl = heroImgStoryMedia?.url || heroImgRelatedMedia?.url || "";
  const heroImgCaption = storyMediaCaption[0] || relatedMedia?.caption;
  const heroImgAlt = storyMediaAlt[0] || "";

  const heroImgWidth =
    heroImgStoryMedia?.width || heroImgRelatedMedia?.width || 900;
  const heroImgHeight =
    heroImgStoryMedia?.height || heroImgRelatedMedia?.height || 600;

  const mainVideoUrl = storyMedia.find((i) => typeof i === "string") || "";

  const processedElements = processElements(elements);
  const {
    headline = "",
    standfirst = "",
    bodyElements = [],
    mainMedia = [],
  } = processedElements;

  const category = sections[0]?.uniqueName || "";

  const displayType = context?.displaySetting?.displayType ?? "";
  const seoFields = context?.seoFields;
  const ogFields = context?.ogFields;
  const ogImage = context?.ogImages ?? [];
  const ogImageUrl = ogImage[0]?.content?.fields?.["original"]?.url;

  return {
    id,
    headline,
    standfirst,
    heroImgUrl,
    mainVideoUrl,
    heroImgCaption,
    heroImgAlt,
    mainMedia,
    heroImgWidth,
    heroImgHeight,
    authors,
    translators,
    publishedDate,
    bodyElements,
    tags,
    category,
    displayType,
    seoFields,
    ogFields,
    ogImageUrl,
  };
}

export function getRecommendedArticlesFromOSResponse(
  oSSearchHits: OSSearchHits<PartialCueArticle>[],
): ArticleCard[] {
  const recommendedArticles: ArticleCard[] = oSSearchHits.map(
    getArticleCardDataFromOsSearchHit,
  );

  return recommendedArticles;
}

export default function useArticle(): TRouteWithoutRedirect<
  ArticlePageListing | null,
  string
> {
  const customContext: CustomContext<
    TRouteWithoutRedirect<ArticlePageListing | null, string>
  > = useRouteContext();

  const tResponse: TRouteWithoutRedirect<ArticlePageListing | null, string> =
    customContext.context;

  return tResponse;
}
