import cx from "classnames";
import type { ReactElement } from "react";

import styles from "./ArticleHeader.module.css";

type Props = Readonly<{
  children: string;
  isLarger?: boolean;
  testId?: string;
}>;

export default function ArticleHeader({
  children,
  isLarger,
  testId,
}: Props): ReactElement {
  return (
    <h1
      data-testid={testId}
      className={cx(styles.header, { [styles.isLarger]: isLarger })}
    >
      {children}
    </h1>
  );
}
