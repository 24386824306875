import StickyCard from "@src/app/components/Blocks/StickyCard/StickyCard";
import Advertisement from "@src/app/components/Elements/Advertisement/Advertisement";
import { GoogleAdsSlotFactory } from "@src/app/components/Elements/Advertisement/helpers";
import StickyContainer from "@src/app/components/Layouts/StickyContainer/StickyContainer";
import useWindowDimensions from "@src/app/hooks/useWindowDimensions";
import { getAuthorCards } from "@src/app/pages/Article/helpers";
import { AdvertisementTypeEnum } from "@src/app/types/Ads.d";
import { ArticleCard, Author } from "@src/app/types/Article"; // Import Article type
import cx from "classnames";
import { type ReactElement, useRef } from "react";

import NewsletterWidget from "../NewsletterWidget/NewsletterWidget";
import PopularReadCardList from "../PopularReadCardList/PopularReadCardList";
import styles from "./ArticleContent.module.css";

export interface IContentRight {
  authors: Author[];
  translators?: string;
  category?: string;
  nlIsDark?: boolean;
  nlIsThinkCulture?: boolean;
  nlHeaderTitle?: string | undefined;
  nlBodyText?: string | undefined;
  popularThisMonth?: ArticleCard[];
  testId?: string;
  contentLeftHeight: number;
}

export default function ContentRight({
  authors,
  translators,
  category = "",
  nlIsDark,
  nlIsThinkCulture,
  nlHeaderTitle = "",
  nlBodyText = "",
  popularThisMonth = [], // Provide a default value to avoid undefined
  contentLeftHeight,
}: IContentRight): ReactElement {
  const authorCards = getAuthorCards(authors);
  const dimensions = useWindowDimensions();
  const isTablet = dimensions.width > 992;

  // ref
  const authorCardsContainerRef = useRef<HTMLDivElement | null>(null);
  const translatorContainerRef = useRef<HTMLDivElement | null>(null);

  // container height use to deduct for sticky container
  const authorCardsContainerHeight =
    authorCardsContainerRef.current?.offsetHeight || 0;
  const translatorContainerHeight =
    translatorContainerRef.current?.offsetHeight || 0;

  // sticky container height
  const stickyMinHeight =
    contentLeftHeight - authorCardsContainerHeight - translatorContainerHeight;

  return (
    <>
      {isTablet && (
        <>
          <div className={cx(styles.authorAndTranslator)}>
            {/* Author Cards */}
            <div ref={authorCardsContainerRef}>{authorCards}</div>

            {/* Translator */}
            {translators && (
              <div
                ref={translatorContainerRef}
                className={styles.translatorContainer}
              >
                Translated by {translators}
              </div>
            )}
          </div>

          {/* Ads IMU2 , Newsletter Widget, Popular This Month */}
          <StickyContainer minHeight={`${stickyMinHeight}px`}>
            {/* Ads IMU2 */}
            <StickyCard>
              <Advertisement
                rootClassName={styles.adImu2}
                adUnitProps={{
                  type: AdvertisementTypeEnum.IMU2,
                  slot: GoogleAdsSlotFactory.imu2(category),
                }}
              />
            </StickyCard>

            {/* Newsletter Widget */}
            <StickyCard>
              <div className={styles.newsletterDesktop}>
                <NewsletterWidget
                  isDark={nlIsDark}
                  headerTitle={nlHeaderTitle}
                  bodyText={nlBodyText}
                  isThinkCulture={nlIsThinkCulture}
                />
              </div>
            </StickyCard>

            {/* Popular This Month */}
            <StickyCard>
              {popularThisMonth.length > 0 && (
                <PopularReadCardList articles={popularThisMonth} />
              )}
            </StickyCard>
          </StickyContainer>
        </>
      )}
    </>
  );
}
