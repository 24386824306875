import Column from "@src/app/components/Layouts/Column/Column";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import Row from "@src/app/components/Layouts/Row/Row";
import useWindowDimensions from "@src/app/hooks/useWindowDimensions";
import cx from "classnames";
import { ReactElement, ReactNode } from "react";

import styles from "./HeroLayout.module.css";

export type Props = Readonly<{
  testId?: string;
  children: ReactNode[];
}>;

export default function HeroLayout({
  children,
  testId = "hero-layout",
}: Props): ReactElement {
  const dimensions = useWindowDimensions();
  const isTablet = dimensions.width >= 992;

  return (
    <div data-testid={testId} className={cx(styles.heroLayoutWrapper)}>
      <MainContainer>
        <Row>
          {children.length > 1 ? (
            <>
              <Column className={cx(styles.colLeft)}>{children[0]}</Column>
              <Column className={cx(styles.colRight)}>
                <Row>
                  <Column className={cx(styles.col2Tablet)}>
                    {children[1]}
                  </Column>
                  {isTablet ? null : (
                    <Column>
                      <hr className={styles.divider} />
                    </Column>
                  )}
                  <Column className={cx(styles.col2Tablet)}>
                    {children[2]}
                  </Column>
                  {isTablet ? null : (
                    <Column>
                      <hr className={styles.divider} />
                    </Column>
                  )}
                </Row>

                <Row>
                  <Column className={cx(styles.col2Tablet)}>
                    {children[3]}
                  </Column>
                  {isTablet ? null : (
                    <Column>
                      <hr className={styles.divider} />
                    </Column>
                  )}
                  <Column className={cx(styles.col2Tablet)}>
                    {children[4]}
                  </Column>
                </Row>
              </Column>
            </>
          ) : (
            <Column className={styles.banner}>{children[0]}</Column>
          )}
        </Row>
      </MainContainer>
    </div>
  );
}
