import PlaceholderImage from "@src/app/assets/images/placeholder.svg";
import CategoryLabel, {
  Props as CategoryLabelProps,
} from "@src/app/components/Elements/CategoryLabel/CategoryLabel";
import useWindowDimensions from "@src/app/hooks/useWindowDimensions";
import { Author } from "@src/app/types/Article";
import type { ReactElement } from "react";

import InternalLink from "../../Elements/Typography/InternalLink/InternalLink";
import styles from "./PopularReadCard.module.css";

export type Props = Readonly<{
  authors: Author[];
  categoryLabelProps: CategoryLabelProps;
  imgUrl: string;
  articleTitle: string;
  articlePath: string;
  testId?: string;
}>;

export default function PopularReadCard({
  imgUrl,
  articlePath,
  articleTitle,
  authors,
  categoryLabelProps,
  testId,
}: Props): ReactElement {
  const dimensions = useWindowDimensions();
  const isTablet = dimensions.width > 992;

  // const truncateDescription = (text: string, maxLength: number): string => {
  //   if (typeof text !== "string") {
  //     return ""; // Return an empty string or handle it as needed
  //   }

  //   if (text.length <= maxLength) {
  //     return text;
  //   }

  //   // Trim the text to the maximum length
  //   const truncatedText = text.substring(0, maxLength);

  //   // Find the last space in the truncated text to ensure a proper word cut-off
  //   const lastSpaceIndex = truncatedText.lastIndexOf(" ");

  //   // If a space is found, truncate the text at the last space
  //   if (lastSpaceIndex !== -1) {
  //     return truncatedText.substring(0, lastSpaceIndex) + "...";
  //   }

  //   // If no space is found, simply add ellipsis
  //   return truncatedText + "...";
  // };

  return (
    <InternalLink to={articlePath}>
      <div data-testid={testId} className={styles.popularReadCard}>
        <div className={styles.thumbnail}>
          <img src={imgUrl || PlaceholderImage} alt={articleTitle} />
        </div>
        <div className={styles.content}>
          <CategoryLabel
            transparentBackground={isTablet}
            {...categoryLabelProps}
          />
          <p className={styles.title}>{articleTitle}</p>
          {authors.length > 0 && (
            <p className={styles.author}>
              By{" "}
              {authors.map((author, i) => [
                <InternalLink
                  key={author.name}
                  className={styles.authorLink}
                  to={author.url || "/"}
                >
                  {author.name}
                </InternalLink>,
                i < authors.length - 1 && ", ",
              ])}
            </p>
          )}
        </div>
      </div>
    </InternalLink>
  );
}
