import IconComic from "@src/app/assets/images/icon-comic.svg";
import cx from "classnames";
import type { ReactElement } from "react";

import styles from "./ComicLightbox.module.css";

export type Props = Readonly<{
  parentTestId?: string;
}>;

export default function ComicTextWrapper({
  parentTestId,
}: Props): ReactElement {
  return (
    <div
      className={cx(styles.textWrapper)}
      data-testid={`${parentTestId}-text-wrapper`}
    >
      <div className={styles.icon}>
        <img src={IconComic} alt="Panel View Icon"></img>
      </div>
      <span>Click on an image to enter panel view</span>
    </div>
  );
}
