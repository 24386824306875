import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import ShareButtons from "@src/app/components/Blocks/ShareButtons/ShareButtons";
import ResponsiveIframe from "@src/app/components/Elements/ResponsiveIframe/ResponsiveIframe";
import Spinner from "@src/app/components/Elements/Spinner/Spinner";
import ArticleBlurb from "@src/app/components/Elements/Typography/ArticleBlurb/ArticleBlurb";
import InternalLink from "@src/app/components/Elements/Typography/InternalLink/InternalLink";
import formatPublishedDate from "@src/app/helper/formatPublishedDate";
import {
  ResolvedCUEElement,
  ResolvedImageCUEElement,
} from "@src/app/hooks/useArticle/elements";
import useWindowDimensions from "@src/app/hooks/useWindowDimensions";
import {
  getArticleBody,
  getArticleBodyComic,
  getArticleTags,
  getAuthorCards,
  GetRecommendedArticles,
} from "@src/app/pages/Article/helpers";
import { ArticleCard, Author } from "@src/app/types/Article";
import { CUETags } from "@src/app/types/Opensearch";
import cx from "classnames";
import type { ReactElement } from "react";
import { useEffect, useRef, useState } from "react";

import Annotation from "../Annotation/Annotation";
import ArticleImage from "../ArticleImage/ArticleImage";
import PopularReadCardList from "../PopularReadCardList/PopularReadCardList";
import styles from "./ArticleContent.module.css";

export interface IContentLeft {
  authors: Author[];
  translators?: string;
  nlIsDark?: boolean;
  nlIsThinkCulture?: boolean;
  nlHeaderTitle?: string;
  nlBodyText?: string;
  standfirst?: string;
  heroImgUrl?: string;
  heroImgCaption?: string | undefined;
  heroImgAlt?: string | undefined;
  heroImgWidth?: number;
  heroImgHeight?: number;
  mainMedia: ResolvedCUEElement[];
  bodyElements: ResolvedCUEElement[];
  tags: CUETags[];
  recommendedArticles?: ArticleCard[];
  recommendedArticlesIsLoading?: boolean;
  isArticleAComic?: boolean;
  isArticleVideo?: boolean;
  isArticleBigRead?: boolean;
  isArticlePhotoStory?: boolean;
  isOutbrainEnabled?: boolean;
  pathname: string;
  date: string;
  category?: string;
  mainVideoUrl?: string | undefined;
  popularThisMonth?: ArticleCard[];
  testId?: string;
}

export default function ContentLeft({
  authors,
  translators,
  standfirst = "",
  heroImgUrl = "",
  heroImgCaption,
  heroImgAlt,
  heroImgWidth,
  heroImgHeight,
  mainMedia = [],
  bodyElements,
  tags,
  recommendedArticles,
  recommendedArticlesIsLoading,
  isArticleAComic,
  isArticleVideo,
  isArticleBigRead,
  isArticlePhotoStory,
  isOutbrainEnabled,
  pathname,
  date,
  category,
  mainVideoUrl,
  popularThisMonth = [], // Provide a default value to avoid undefined
  testId = "content-left",
}: IContentLeft): ReactElement {
  const authorCards = getAuthorCards(authors);
  const dimensions = useWindowDimensions();
  const isTablet = dimensions.width > 992;
  const articleTags = getArticleTags(tags);

  // Display type that is in full width
  const isFeaturedArticle = isArticleBigRead || isArticlePhotoStory;

  // Slice the recommendedArticles based on isArticleAComic condition
  const articlesToShow = isArticleAComic || isFeaturedArticle ? 4 : 3;
  const articleRecommendedArticles = GetRecommendedArticles(
    (recommendedArticles as ArticleCard[]).slice(0, articlesToShow),
  );
  const articleBody = isArticleAComic
    ? getArticleBodyComic(bodyElements)
    : getArticleBody(
        bodyElements,
        category,
        isFeaturedArticle,
        isArticleAComic,
      );

  // Create a ref for the specific div
  const recomendedArticleView = useRef<HTMLDivElement | null>(null);
  const sendDataToGTM = useGTMDispatch();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust threshold as needed (0.5 means at least 50% of the div should be visible)
    };

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (
          entry.isIntersecting &&
          !isLoaded &&
          !recommendedArticlesIsLoading
        ) {
          // Call sendDataToGTM method when the div becomes visible
          sendDataToGTM({
            event: "tcCustomEvent",
            eventCategory: "recommended article",
            eventLabel: "recommended article",
            eventAction: "view",
          });
          setIsLoaded(true);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    const currentRef = recomendedArticleView.current; // Copy the ref value to a variable

    if (currentRef) {
      observer.observe(currentRef);
    }

    // Clean up the observer when the component unmounts
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [recommendedArticlesIsLoading, isLoaded, sendDataToGTM]);

  // const mainMediaElement = mainMedia[0];
  const mainMediaCaption = (
    mainMedia[0]?.children?.[0] as ResolvedImageCUEElement | undefined
  )?.fields?.caption;
  const mainMediaCredit = (
    mainMedia[0]?.children?.[0] as ResolvedImageCUEElement | undefined
  )?.fields?.credit;

  console.log(mainMedia, "mainMedia", mainMediaCaption);

  const captionNode = mainMediaCaption ? (
    <Annotation
      element={{
        annotations: mainMediaCaption.annotations || [],
        value: mainMediaCaption.value || "",
      }}
    />
  ) : null;
  const creditNode = mainMediaCredit ? (
    <Annotation
      element={{
        annotations: mainMediaCredit.annotations || [],
        value: mainMediaCredit.value || "",
      }}
    />
  ) : null;

  const captionNodeWithCreditNode = mainMediaCaption ? (
    <>
      <span>{captionNode}</span>
      {creditNode ? <span> ({creditNode})</span> : null}
    </>
  ) : (
    heroImgCaption
  );

  return (
    <>
      <div data-testid={testId}>
        {/* Article Image: For Featured Article */}
        {isFeaturedArticle && (
          <>
            {isArticleVideo && mainVideoUrl ? (
              <ResponsiveIframe src={mainVideoUrl} />
            ) : (
              heroImgUrl && (
                <ArticleImage
                  src={heroImgUrl}
                  alt={heroImgAlt || heroImgCaption}
                  caption={captionNodeWithCreditNode}
                  width={heroImgWidth}
                  height={heroImgHeight}
                />
              )
            )}
          </>
        )}

        {/* Article General Info */}
        <div
          className={cx({
            [styles.indentBody]: isFeaturedArticle || isArticleAComic,
          })}
        >
          {/* Category & Social Share */}
          <div className={cx(styles.categoryAndSocialShare)}>
            {/* Published Date */}
            <div className={styles.categorySection}>
              {date && (
                <>
                  <span className={styles.category}>
                    {formatPublishedDate(date)}
                  </span>
                  <div className={styles.separator}></div>
                </>
              )}
              <span className={styles.category}>
                <InternalLink to={`/${category}`}>{category}</InternalLink>
              </span>
            </div>

            {/* Social Share */}
            <div className={styles.shareButtonsSection}>
              <ShareButtons expandable={isFeaturedArticle || isArticleAComic} />
            </div>
          </div>

          {/* Author and Translator */}
          {((!isFeaturedArticle && !isTablet) ||
            (!isArticleVideo && !isTablet) ||
            isFeaturedArticle ||
            isArticleAComic) && (
            <div className={cx(styles.authorAndTranslator)}>
              {/* Author Cards */}
              <div className={styles.authorContainer}>{authorCards}</div>

              {/* Translator */}
              {translators && (
                <div className={styles.translatorContainer}>
                  <div className={styles.translatorInfo}>
                    <div className={styles.translatorText}>
                      Translated by {translators}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Article Blurb */}
          <ArticleBlurb isFeatured={isFeaturedArticle || isArticleAComic}>
            {standfirst}
          </ArticleBlurb>
        </div>

        {/* Article Image: Default Article */}
        {!isFeaturedArticle && !isArticleAComic && (
          <>
            {isArticleVideo && mainVideoUrl ? (
              <ResponsiveIframe src={mainVideoUrl} hasMargin />
            ) : (
              heroImgUrl && (
                <ArticleImage
                  src={heroImgUrl}
                  alt={heroImgAlt || heroImgCaption}
                  caption={captionNodeWithCreditNode}
                  width={heroImgWidth}
                  height={heroImgHeight}
                />
              )
            )}
          </>
        )}

        {/* Article Body */}
        <div
          className={cx({
            [styles.indentBody]: isFeaturedArticle,
          })}
        >
          {articleBody}
        </div>

        {/* Article Tags */}
        <div
          className={cx({
            [styles.indentBody]: isFeaturedArticle || isArticleAComic,
          })}
        >
          <div className={styles.tags}>{articleTags}</div>
        </div>

        {/* Popular This Month */}
        {isArticleAComic
          ? ""
          : !isTablet && (
              <div className={styles.popularThisMonth}>
                {popularThisMonth.length > 0 && (
                  <PopularReadCardList articles={popularThisMonth} />
                )}
              </div>
            )}

        {/* Recommended Articles */}
        {recommendedArticlesIsLoading ? (
          <Spinner />
        ) : (
          <div
            className={cx("recommendedStories", styles.recommendedArticles)}
            ref={recomendedArticleView}
          >
            {articleRecommendedArticles}
          </div>
        )}

        {/* Outbrain */}
        {isOutbrainEnabled && (
          <div
            className="OUTBRAIN"
            data-src={`https://www.thinkchina.sg${pathname}`}
            data-widget-id="AR_1"
          ></div>
        )}
      </div>
    </>
  );
}
