// import Icon from "@src/app/assets/images/newsletter-culture-icon.svg";
import SignupForm from "@src/app/components/Blocks/NewsletterSignupForm/NewsletterSignupForm";
import cx from "classnames";
import type { ReactElement } from "react";

import styles from "./NewsletterWidget.module.css";

type Props = Readonly<{
  headerTitle: string;
  bodyText: string;
  /** @deprecated */
  isDark?: boolean;
  isThinkCulture?: boolean;
  testId?: string;
}>;

export default function NewsletterWidget({
  isThinkCulture,
  testId,
  headerTitle,
  bodyText,
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={cx(styles.newsletterWidgetContainer)}>
      {/* <img src={NewsletterWidgetBgArticle} /> */}
      <div
        data-testid={`${testId}-innerContainer`}
        className={cx(styles.innerContainer)}
      >
        {/* <div className={styles.emptyDiv}></div> */}
        <div className={styles.contentContainer}>
          <div
            data-testid={`${testId}-headerTitle`}
            className={styles.headerTitle}
          >
            {headerTitle}
          </div>

          <div data-testid={`${testId}-bodyText`} className={styles.bodyText}>
            {bodyText}
          </div>
          <SignupForm
            isThinkCulture={isThinkCulture}
            placeholder="Subscribe with email"
          />
        </div>
      </div>
    </div>
  );
}
