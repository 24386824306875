import type { ReactElement } from "react";

import styles from "./Footer.module.css";

export default function FooterInfo(): ReactElement {
  return (
    <div className={styles.introText}>
      ThinkChina is an English language e-magazine with a China focus and
      powered by&nbsp;
      <a
        href="https://sph.com.sg"
        className={styles.textLinks}
        aria-label="Visit SPH Media Limited's official website"
        target="_blank"
        rel="noreferrer"
      >
        Singapore Press Holdings
      </a>
      {"' "}
      flagship Chinese daily{" "}
      <a
        href="https://zaobao.com.sg"
        className={styles.textLinks}
        aria-label="Visit Lianhe Zaobao's official website"
        target="_blank"
        rel="noreferrer"
      >
        Lianhe Zaobao
      </a>
      .
    </div>
  );
}
