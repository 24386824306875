import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlaceholderImage from "@src/app/assets/images/placeholder.svg";
import CategoryLabel, {
  Props as CategoryLabelProps,
} from "@src/app/components/Elements/CategoryLabel/CategoryLabel";
import ImageTag from "@src/app/components/Elements/ImageTag/ImageTag";
import ResponsiveImage from "@src/app/components/Elements/ResponsiveImage/ResponsiveImage";
import Byline from "@src/app/components/Elements/Typography/Byline/Byline";
import InternalLink from "@src/app/components/Elements/Typography/InternalLink/InternalLink";
import Paragraph from "@src/app/components/Elements/Typography/Paragraph/Paragraph";
import { Author } from "@src/app/types/Article";
import { getWindowOrigin } from "@src/app/utils/helpers";
import cx from "classnames";
import { type ReactElement } from "react";

import styles from "./ArticleCardContent.module.css";

export type Props = Readonly<{
  imgUrl: string;
  imgWidth: number;
  imgHeight: number;
  categoryLabelProps: CategoryLabelProps;
  paragraphContent: string;
  articlePath: string;
  articleTitle: string;
  authors: Author[];
  date?: string;
  isHero?: boolean;
  isBig?: boolean;
  hasSnippet?: boolean;
  hasCardShadow?: boolean;
  hasBackground?: boolean;
  hasSidePadding?: boolean;
  hasTitleMargin?: boolean;
  hasAuthorAndDate?: boolean;
  isShorten?: boolean;
  isVideo?: boolean;
  testId?: string;
  eventCategory?: string;
  displayType?: string;
}>;

export default function ArticleCardContent({
  imgUrl,
  imgWidth,
  imgHeight,
  categoryLabelProps,
  paragraphContent,
  articlePath,
  articleTitle,
  authors,
  date,
  isHero,
  isBig,
  hasSnippet,
  hasCardShadow,
  hasBackground,
  hasSidePadding = true,
  hasTitleMargin = hasSnippet,
  isShorten = true,
  isVideo,
  hasAuthorAndDate,
  testId = "article-card-content",
  eventCategory,
  displayType,
}: Props): ReactElement {
  const sendDataToGTM = useGTMDispatch();
  const dataToGTMOnClick = () => {
    sendDataToGTM({
      event: "tcCustomEvent",
      eventCategory: eventCategory,
      eventLabel: getWindowOrigin() + articlePath,
      eventAction: "click",
    });
  };
  return (
    <div
      data-testid={testId}
      className={cx(
        isHero && styles.hero,
        isBig && styles.big,
        styles.articleCardContentContainer,
        hasCardShadow && styles.articleCardContentCardShadow,
        hasBackground && styles.articleCardContentCardBackgroundColor,
      )}
    >
      <div id="articleImageContainer" className={styles.articleImageContainer}>
        <div className={styles.articleDisplayType}>
          {displayType && <ImageTag testId="image-tag" type={displayType} />}
        </div>

        <InternalLink
          to={articlePath}
          onClick={
            eventCategory && eventCategory.trim() !== ""
              ? dataToGTMOnClick
              : undefined
          }
        >
          {isVideo && (
            <div className={styles.articleThumbnailVideoIcon}>
              <div className={styles.videoIconContainer}>
                <FontAwesomeIcon icon={faPlay} color="white" size="xl" />
              </div>
            </div>
          )}

          <ResponsiveImage
            src={imgUrl || PlaceholderImage}
            alt={articleTitle}
            srcWidth={imgWidth}
            srcHeight={imgHeight}
            loading={isHero ? "eager" : "lazy"}
            displayWidth={800}
            fetchPriority={isHero ? "high" : undefined}
          />
        </InternalLink>
      </div>
      <div
        className={cx(
          styles.articleDetailsContainer,
          hasSidePadding && styles.sidePadding,
        )}
      >
        <CategoryLabel {...categoryLabelProps} />
        <InternalLink
          to={articlePath}
          onClick={
            eventCategory && eventCategory.trim() !== ""
              ? dataToGTMOnClick
              : undefined
          }
          aria-label={`Read article: ${articleTitle}`}
        >
          {isHero && isBig ? (
            <h1
              className={cx(
                styles.articleTitle,
                hasTitleMargin ? styles.articleTitleMargin : "",
              )}
            >
              {articleTitle}
            </h1>
          ) : (
            <h2
              className={cx(
                styles.articleTitle,
                hasTitleMargin ? styles.articleTitleMargin : "",
                !isHero && !hasSnippet && !hasAuthorAndDate
                  ? styles.minHeightLong
                  : "",
                !isHero && !hasSnippet && hasAuthorAndDate
                  ? styles.minHeightShort
                  : "",
              )}
            >
              {articleTitle}
            </h2>
          )}
        </InternalLink>

        {hasSnippet && (
          <div className={cx(hasAuthorAndDate && styles.marginBottom)}>
            <Paragraph
              testId={`${testId}-paragraph-snippet`}
              isShorten={isShorten}
            >
              {paragraphContent}
            </Paragraph>
          </div>
        )}

        {hasAuthorAndDate && (
          <Byline testId={`${testId}-span-by`}>
            {authors.length > 0 && (
              <>
                By{" "}
                {authors.map((author, i) => [
                  <InternalLink
                    key={author.name}
                    className={styles.authorLink}
                    to={author.url || "/"}
                  >
                    {author.name}
                  </InternalLink>,
                  i < authors.length - 1 && ", ",
                ])}
                {date && <>&nbsp;&nbsp;|&nbsp;&nbsp;</>}
              </>
            )}
            {date}
          </Byline>
        )}
      </div>
    </div>
  );
}
