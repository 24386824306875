import { Request, Response } from "@sphtech/web2-core/api";
import { ArticleCard } from "@src/app/types/Article";
import fetchCaas, {
  ARTICLE_INDEX,
  STORY_QUEUE_INDEX,
} from "@src/app/utils/caasFetch";
import getArticleCardDataFromOsSearchHit from "@src/app/utils/getArticleCardDataFromSearchHits";
import { logEnd, logStart } from "@src/app/utils/helpers";
import {
  OSResponse,
  OSSearchHits,
  PartialCueArticle,
  StoryQueueListItem,
} from "src/app/types/Opensearch";

function getHeroStoryQueueID(): string {
  return process.env.VITE_CUESQ_HERO_BANNER_ID as string;
}

function getEditorsPickStoryQueueID(): string {
  return process.env.VITE_CUESQ_EDITORS_PICK_ID as string;
}

export const HERO_STORY_QUEUE_TYPE: StoryQueueType = "hero";
export const EDITORS_PICK_STORY_QUEUE_TYPE: StoryQueueType = "editors-pick";
export type StoryQueueType = "hero" | "editors-pick";

const fetchArticleCardsDataByCueIDs = async (
  cueIDs: string[],
): Promise<ArticleCard[] | undefined> => {
  const timeStart = logStart();

  const searchQuery = {
    size: cueIDs.length,
    _source: {
      include: [
        "data.context.id",
        "data.context.elements",
        "data.context.media",
        "data.context.title",
        "data.context.urlPath",
        "data.context.relatedContributorProfile",
        "data.context.sections",
        "data.context.updated",
        "data.context.byline.translator",
      ],
      exclude: ["sqsMessage"],
    },
    query: {
      terms: {
        "data.context.id": cueIDs,
      },
    },
  };

  const osSearchRes: OSResponse = await fetchCaas(
    searchQuery,
    ARTICLE_INDEX,
    true,
  );
  logEnd(
    "storyQueue CaaS fetchArticleCardsDataByCueIDs",
    timeStart,
    ARTICLE_INDEX,
    searchQuery,
  );

  const hits = osSearchRes.payload?.hits?.hits
    ? osSearchRes.payload.hits.hits
    : [];

  if (hits.length > 0) {
    const timeStart = logStart();
    const articleCardData: ArticleCard[] = [];
    // Sort articles based on cueIDs
    cueIDs.map((cueId) => {
      const hit = hits.find(
        (hit) => hit._source?.data?.context?.id === cueId,
      ) as OSSearchHits<PartialCueArticle> | undefined;
      if (hit) {
        articleCardData.push(getArticleCardDataFromOsSearchHit(hit));
      }
    });

    logEnd("storyQueue loop format fetchArticleCardsDataByCueIDs", timeStart);

    return articleCardData;
  }
};

export type GetStoryQueueArticlesRes = {
  data: {
    displayTitle?: string;
    articles: ArticleCard[];
  };
};

export async function GetStoryQueueArticles(req: Request, res: Response) {
  const timeStartMain = logStart();

  const { type } = req.query;

  if (type != HERO_STORY_QUEUE_TYPE && type != EDITORS_PICK_STORY_QUEUE_TYPE) {
    return res.json({
      data: {
        articles: [],
      },
    });
  }

  try {
    const searchQuery = {
      size: 1,
      _source: {
        include: [
          "data.context.id",
          "data.context.fields.displayTitle",
          "data.context.storyQueueList",
        ],
        exclude: ["sqsMessage"],
      },
      query: {
        constant_score: {
          filter: {
            term: {
              "data.context.id":
                type === HERO_STORY_QUEUE_TYPE
                  ? getHeroStoryQueueID()
                  : getEditorsPickStoryQueueID(),
            },
          },
        },
      },
    };

    const timeStart = logStart();
    const osSearchRes: OSResponse = await fetchCaas(
      searchQuery,
      STORY_QUEUE_INDEX,
      true,
    );
    logEnd("storyQueue fetchCaas", timeStart, STORY_QUEUE_INDEX, searchQuery);

    const hits = osSearchRes.payload?.hits?.hits
      ? osSearchRes.payload.hits.hits
      : [];

    let articles: ArticleCard[] = [];
    let displayTitle: string | undefined;
    if (hits.length > 0) {
      const osSearchHits: OSSearchHits<PartialCueArticle>[] =
        hits as OSSearchHits<PartialCueArticle>[];
      const editorPickArticleHit = osSearchHits[0];
      displayTitle =
        editorPickArticleHit._source?.data?.context?.fields?.displayTitle || "";
      const storyQueues: StoryQueueListItem[] =
        osSearchHits[0]?._source?.data?.context?.storyQueueList || [];

      if (storyQueues.length > 0) {
        const timeStart = logStart();
        const storyQueueCueIDs = storyQueues
          .map(
            (heroStoryQueue: StoryQueueListItem) => heroStoryQueue.content.id,
          )
          .filter((_, i: number) => i < 5);
        logEnd("storyQueue loop filter id", timeStart);
        articles =
          (await fetchArticleCardsDataByCueIDs(storyQueueCueIDs)) || [];
      }
    }

    logEnd(
      "storyQueue main user function",
      timeStartMain,
      req.url.toString(),
      req.body,
    );

    res
      .setHeader(
        "Cache-Control",
        "max-age=180,  stale-while-revalidate=60, stale-if-error=86400",
      )
      .json({
        data: {
          articles,
          displayTitle,
        },
      });
  } catch (e: unknown) {
    console.error(e);
  }
}
