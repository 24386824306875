import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import NewsletterWidget from "@src/app/components/Modules/LandingPage/NewsletterWidget/NewsletterWidget";

import styles from "./PopularContentSectionTwo.module.css";

export type ArticleCardBlock = {
  testId?: string;
};

export default function PopularContentSectionTwo({ testId }: ArticleCardBlock) {
  return (
    <>
      <div className={styles.popularContentSectionTwo} data-testid={testId}>
        <MainContainer>
          <NewsletterWidget
            bodyText="Insights on China, right in your mailbox. Sign up now."
            headerTitle="Get the ThinkChina weekly newsletter"
          />
        </MainContainer>
      </div>
    </>
  );
}
