import artist from "@src/app/assets/images/artist.svg";
import MetaTags from "@src/app/components/Blocks/MetaTags/MetaTags";
import AdSettings from "@src/app/components/Elements/Advertisement/AdSettings";
import CatfishAd from "@src/app/components/Elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@src/app/components/Elements/Advertisement/helpers";
import PrestitialAd from "@src/app/components/Elements/Advertisement/PrestitialAd";
import Avatar from "@src/app/components/Elements/Avatar/Avatar";
import GAData from "@src/app/components/Elements/GAData/GAData";
import Paragraph from "@src/app/components/Elements/Typography/Paragraph/Paragraph";
import SectionTitle from "@src/app/components/Elements/Typography/SectionTitle/SectionTitle";
import Column from "@src/app/components/Layouts/Column/Column";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import Row from "@src/app/components/Layouts/Row/Row";
import useGlobalAdSetting from "@src/app/hooks/useGlobalAdSetting";
import { removeLeadingSlash } from "@src/app/utils/helpers";
import { useLocation } from "react-router-dom";

import styles from "./AboutUs.module.css";
import { editors } from "./editors";

export default function AboutUsPage() {
  const { pathname } = useLocation();
  const { isPrestitialEnabled, topOverlayImpressions, isCatfishEnabled } =
    useGlobalAdSetting();

  return (
    <>
      <AdSettings />

      <MetaTags
        title="About ThinkChina, English language e-magazine with China & Greater China focus"
        description="Our Chinese name (思想中国) ThinkChina's Chinese logo is a traditional seal carving with a modern twist. While most seals are carved on stones, our logo is carved on a ceramic sculpture created by Singaporean artist Oh Chai Hoo. The carved logo is a fusion of three different scripts: Han dynasty brick engraving (砖文), Song typeface (宋体), and simplified Chinese characters."
        ogType=""
        slug={removeLeadingSlash(pathname)}
      />

      <GAData
        title="About Us"
        level2="about-us"
        contenttype="index/listing page"
      />

      <PrestitialAd
        slot={GoogleAdsSlotFactory.prestitial()}
        isPrestitialEnabled={isPrestitialEnabled}
        topOverlayImpressions={topOverlayImpressions}
      />

      <MainContainer>
        <div className={styles.about}>
          <section className={styles.section}>
            <Row>
              <Column>
                <SectionTitle>About us</SectionTitle>
                <Paragraph>
                  <p>
                    ThinkChina is an English-language e-magazine focused on
                    China under SPH Media Limited&apos;s flagship Chinese daily,
                    Lianhe Zaobao<span className={styles.footnote}>*</span>. We
                    publish original reporting, opinion pieces and columns
                    across a wide spectrum of topics, covering political,
                    economic, socio-cultural and technological developments in
                    China and the Greater China region.
                  </p>
                </Paragraph>

                <Paragraph>
                  <p>
                    Our extensive network of Chinese-language journalists and
                    writers are mainly from Singapore, China and other parts of
                    Asia. We seek to bring their nuanced and insightful
                    perspectives to the English-speaking world through
                    thoughtful curation and meticulous translation. This myriad
                    of voices is projected from the unique vantage point of
                    multi-ethnic Singapore — a proverbial little red dot in
                    Southeast Asia.
                  </p>
                </Paragraph>

                <Paragraph>
                  <p>
                    ThinkChina won Gold for Best News Website or Mobile Service
                    at the 2020 World Association of News Publishers (WAN-IFRA)
                    Asian Digital Media Awards. At the 2024 Eppy Awards, it
                    placed third for{" "}
                    <a
                      className={styles.grey}
                      href="https://www.thinkchina.sg/"
                    >
                      Best Magazine Website
                    </a>
                    , joint first place for{" "}
                    <a
                      className={styles.grey}
                      href="https://www.thinkchina.sg/cartoon/comic-other-side-coral-reef"
                    >
                      Best Editorial/Political Cartoon
                    </a>{" "}
                    and second place for{" "}
                    <a
                      className={styles.grey}
                      href="https://www.thinkchina.sg/tags/vox-pop"
                    >
                      {" "}
                      Best News or Event Feature Video
                    </a>{" "}
                    in the &quot;fewer than 1 million unique visitors&quot;
                    category.
                  </p>
                </Paragraph>
                <Paragraph>
                  <p>
                    <span className={styles.footnote}>*</span>Lianhe Zaobao is a
                    Chinese-language daily published by SPH Media Limited. It is
                    one of the few foreign-owned Chinese language media
                    accessible online in China. It has a monthly unique
                    visitorship of 5 million, and a monthly pageview count of
                    100 million in China.
                  </p>
                </Paragraph>
              </Column>
            </Row>
          </section>

          <section className={`${styles.section} ${styles.oursection}`}>
            <Row>
              <Column>
                <SectionTitle>Our team</SectionTitle>
                <Row>
                  {editors.map(({ name, bio, designation, image }) => (
                    <Column key={name} className={styles.col6Tablet}>
                      <div className={styles.editorCard}>
                        <div className={styles.profile}>
                          <Row isVCenter={true}>
                            <Column className={styles.photo}>
                              <div className={styles.image}>
                                <div style={{ height: "80px", width: "80px" }}>
                                  <Avatar alt={name} src={image} />
                                </div>
                              </div>
                            </Column>
                            <Column className={styles.title}>
                              <div className={styles.editorName}>{name}</div>
                              <div className={styles.editorDesignation}>
                                {designation}
                              </div>
                            </Column>
                          </Row>
                        </div>
                        <div className={styles.editorInfo}>
                          <div dangerouslySetInnerHTML={{ __html: bio }} />
                        </div>
                      </div>
                    </Column>
                  ))}
                </Row>
              </Column>
            </Row>
          </section>

          <section className={styles.section}>
            <SectionTitle>Pitches and Submissions</SectionTitle>
            <Paragraph>
              <p>
                We are open to pitches from analysts and commentators offering
                global perspectives on China. We are also keen to hear from
                freelance feature writers, photographers, comic artists or video
                content producers based in China.
              </p>
            </Paragraph>

            <Paragraph>
              <p>
                If you have an idea or story proposal you would like to share
                with us, please send us an{" "}
                <a href="mailto:thinkchina@sph.com.sg">
                  email (thinkchina@sph.com.sg)
                </a>{" "}
                with the following elements:
              </p>
            </Paragraph>

            <h4>Commentaries and opinion pieces:</h4>
            <Paragraph>
              <p>
                (i) A paragraph or two stating the article title and what you
                would be writing about. It would be good to explain the
                relevance of the proposed article to current events, reasons why
                this topic should be further delved into, and why your subject
                expertise or vantage point could provide some interesting
                insights on the issue.
              </p>
            </Paragraph>

            <Paragraph>
              <p>
                (ii) If submitting a draft piece that you would like us to take
                a look at, please attach a word document including hyperlinks in
                the text to relevant sources. The word length would usually be
                around 1,000 words.
              </p>
            </Paragraph>

            <h4>Feature stories:</h4>
            <Paragraph>
              <p>
                (iii) In addition to (i), for feature story pitches, please
                include an outline of the piece, the interviewees you have in
                mind and image/video possibilities.
              </p>
            </Paragraph>

            <h4>Cartoons and comics:</h4>
            <Paragraph>
              <p>(iv) Please send fully drawn cartoons.</p>
            </Paragraph>

            <Paragraph>
              <p>
                For all pitches and submissions, please provide links to
                previously published pieces if available; your name, email
                address and contact number for correspondence; and a short
                author bio stating your current designation and affiliation.
                Pieces proposed or submissions should not have been published
                elsewhere. We do accept submissions in Chinese, but the
                honorarium would be adjusted accordingly in view of the
                translation work that would need to be done before publication.
                As we receive a high volume of emails at times, if you do not
                hear from us within two weeks, please do send your proposals on
                to other publications.
              </p>
            </Paragraph>
          </section>

          <section className={`${styles.section} ${styles.oursection}`}>
            <SectionTitle>Our Chinese name (思想中国)</SectionTitle>
            <Paragraph>
              <p>
                ThinkChina&apos;s Chinese logo is a traditional seal carving
                with a modern twist. While most seals are carved on stones, our
                logo is carved on a ceramic sculpture created by Singaporean
                artist Oh Chai Hoo.
              </p>
            </Paragraph>
            <Paragraph>
              <p>
                The carved logo is a fusion of three different scripts: Han
                dynasty brick engraving (砖文), Song typeface (宋体), and
                simplified Chinese characters.
              </p>
            </Paragraph>
            <Paragraph>
              <p>
                Chai Hoo gave much thought to the design. &ldquo;思想&rdquo;
                (think) has its Chinese character &ldquo;思&rdquo; slightly
                tilted, cleverly mimicking a person&apos;s tilted head while
                thinking. &ldquo;想&rdquo; is made up of three parts, the part
                &ldquo;目&rdquo; (eye) is carved horizontally to resemble an
                eye; the idea is for one to stand beside a tree (木), to see
                with our eyes (目) and to ponder with our heart (心). A more
                structured look is adopted for &ldquo;中国&rdquo; (China). The
                word &ldquo;中&rdquo; (middle) has retained this balanced form
                for more than 3500 years, while &ldquo;国&rdquo; (country)
                rendered in simplified Chinese character, gives a stable and
                firm base to the design.
              </p>
            </Paragraph>
            <Paragraph>
              <p>
                There is a &ldquo;pixelated&rdquo; border framing the words
                &ldquo;思想中国&rdquo; (ThinkChina), but &ldquo;思想&rdquo;
                (think) ventures beyond the borders, encouraging us to think
                outside the box. Lastly, the ceramic sculpture mimics a
                contemporary building, strongly rooted while looking forward and
                upward, an embodiment of the artist&apos;s hopes for ThinkChina.
              </p>
            </Paragraph>

            <section className={styles.section}>
              <div className={styles.artistImageDesktop}>
                <img alt="Mr Oh Chai Hoo" src={artist} />
              </div>
              <div className={styles.artistSection}>
                <div className={styles.artistText}>
                  <h3 className={styles.sectionTitle}>About the artist</h3>
                  <Paragraph>
                    <p>
                      Mr Oh Chai Hoo graduated from Nanyang Academy of Fine Arts
                      with a specialisation in Western painting. When he was in
                      Secondary One, his art teacher encouraged him to take up
                      art classes at a community club. At 14, he started
                      painting by the Singapore River, where experienced artists
                      would look over his shoulder at his paintings and give him
                      impromptu masterclasses. He believes that everyone is born
                      with a mission, and his is to be an artist. His art
                      philosophy is to discover emotions and memories (even
                      painful ones), and to translate these into his creations.
                      He has never forgotten why he got started on art, and will
                      continue to keep at it.
                    </p>
                  </Paragraph>
                </div>
                <div className={styles.artistImage}>
                  <img alt="Mr Oh Chai Hoo" src={artist} />
                </div>
              </div>
            </section>
          </section>
        </div>
      </MainContainer>

      <CatfishAd
        slot={GoogleAdsSlotFactory.catfish()}
        isCatfishEnabled={isCatfishEnabled}
      />
    </>
  );
}
