import { useEffect, useState } from "react";

function getWindowDimensions() {
  if (typeof window != "undefined") {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  return { width: 0, height: 0 };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  const [isTablet, setIsTablet] = useState(true); // true in ssr render, since what we mainly want is in Desktop Layout

  useEffect(() => {
    setIsTablet(windowDimensions.width > 992);
  }, [windowDimensions.width]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    if (typeof window != "undefined") {
      // should trigger once
      handleResize();

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  return {
    ...windowDimensions,
    isTablet,
  };
}
