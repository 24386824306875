import PlaceholderImage from "@src/app/assets/images/placeholder.svg";
import type { ReactElement } from "react";

import styles from "./AuthorCard.module.css";

type Props = Readonly<{
  name: string;
  url: string;
  designation: string;
  imageUrl: string;
  testId?: string;
}>;

export default function AuthorCard({
  name,
  url,
  designation,
  imageUrl,
  testId = "author-card",
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={styles.authorCardContainer}>
      <div className={styles.row}>
        <img
          data-testid={`${testId}-image`}
          className={styles.avatar}
          src={imageUrl || PlaceholderImage}
          alt={name}
        />
        <div className={styles.column}>
          <div className={styles.nameContainer}>
            <span className={styles.byText}>By </span>
            <a
              data-testid={`${testId}-name`}
              href={url}
              className={styles.name}
              aria-label={`Author Name: ${name}`}
            >
              {name}
            </a>
          </div>
          <div
            className={styles.designation}
            data-testid={`${testId}-designation`}
          >
            {designation}
          </div>
        </div>
      </div>
    </div>
  );
}
