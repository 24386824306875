// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useWindowDimensions from "@src/app/hooks/useWindowDimensions";
import { ArticleCard } from "@src/app/types/Article";
import cx from "classnames";
import { ReactElement, useRef, useState } from "react";
import SwiperCore from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import ArticleCardContent from "../../Blocks/ArticleCardContent/ArticleCardContent";
import BlockTitle from "../../Elements/Typography/BlockTitle/BlockTitle";
import MainContainer from "../MainContainer/MainContainer";
import styles from "./PopularThisMonthCarousel.module.css";

export type Props = Readonly<{
  articles: ArticleCard[];
  testId?: string;
}>;

export default function PopularThisMonthCarousel({
  articles,
  testId = "swiper-layout-wrapper",
}: Props): ReactElement {
  const dimensions = useWindowDimensions();
  const isTablet = dimensions.width > 992;
  const swiperRef = useRef<SwiperCore>();

  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);

  const sliderSettings = {
    992: {
      slidesPerGroup: 3.3,
      slidesPerView: 3.3,
      spaceBetween: 40,
    },
  };

  const handleSlideChange = (swiper: SwiperCore) => {
    const currentIndex = swiper.activeIndex;
    const slidesCount = swiper.slides.length;
    const slidesPerView = swiper.params.slidesPerView as number;

    // Calculate the last index that represents the last set of visible slides
    const lastVisibleIndex = slidesCount - slidesPerView;

    // Check if the current slide is the first or last
    setIsFirstSlide(currentIndex === 0);
    setIsLastSlide(currentIndex >= lastVisibleIndex);
  };

  return (
    <div data-testid={testId} className={cx(styles.popularThisMonthCarousel)}>
      <MainContainer>
        <div className={cx(styles.popularThisMonthCarouselHeader)}>
          <BlockTitle>Popular this Month</BlockTitle>
          <div className={cx(styles.popularThisMonthCarouselNavigation)}>
            <button
              onClick={() => swiperRef.current?.slidePrev()}
              className={cx(isFirstSlide && styles.disabled)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button
              onClick={() => swiperRef.current?.slideNext()}
              className={cx(isLastSlide && styles.disabled)}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={12}
          slidesPerView={1.2}
          slidesPerGroup={1}
          pagination={{ clickable: true }}
          breakpoints={sliderSettings}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          onSlideChange={handleSlideChange}
          className={cx(
            isFirstSlide ? "" : isTablet ? "shadeLeftOn" : "",
            isLastSlide ? (isTablet ? "" : "shadeLeftOn") : "shadeRightOn",
          )}
        >
          {articles.map((article: ArticleCard) => {
            const {
              articleName,
              articlePath,
              id,
              blurb,
              category,
              authors,
              imgUrl,
              imgWidth,
              imgHeight,
              displayType,
            } = article;

            // Adding refcode for CTR tracking for hero-articles
            const filteredArticlePath = `${articlePath}?ref=home-popular-this-month`;

            return (
              <SwiperSlide key={id}>
                <ArticleCardContent
                  key={id}
                  articlePath={filteredArticlePath}
                  articleTitle={articleName}
                  authors={authors}
                  categoryLabelProps={{
                    href: category.path,
                    label: category.name,
                  }}
                  hasAuthorAndDate
                  hasSidePadding={false}
                  hasBackground
                  hasTitleMargin
                  imgUrl={imgUrl}
                  imgWidth={imgWidth}
                  imgHeight={imgHeight}
                  paragraphContent={blurb}
                  isVideo={displayType == "video"}
                  testId="article-card-content-popular"
                  displayType={displayType}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </MainContainer>
    </div>
  );
}
