import HighlightContainer from "@src/app/components/Layouts/HighlightContainer/HighlightContainer";
import PopularThisMonthCarousel from "@src/app/components/Layouts/PopularThisMonthCarousel/PopularThisMonthCarousel";
import { ArticleCard } from "@src/app/types/Article";

import styles from "./PopularContentSectionOne.module.css";

export type ArticleCardBlock = {
  articles: ArticleCard[];
  testId?: string;
};

export default function PopularContentSectionOne({
  articles,
  testId,
}: ArticleCardBlock) {
  return (
    <div className={styles.popularContentSectionOne} data-testid={testId}>
      <HighlightContainer>
        <PopularThisMonthCarousel articles={articles} />
      </HighlightContainer>
    </div>
  );
}
