import { type ReactElement, type ReactNode } from "react";

import styles from "./StickyCard.module.css";

type Props = Readonly<{
  children: ReactNode;
}>;

export default function StickyCard({ children }: Props): ReactElement {
  return (
    <div className={styles.stickyCard}>
      <div className={styles.stickyItem}>{children}</div>
    </div>
  );
}
