import MetaTags from "@src/app/components/Blocks/MetaTags/MetaTags";
import NotFound from "@src/app/components/Blocks/NotFound/NotFound";
import Pagination from "@src/app/components/Blocks/Pagination/Pagination";
import AdSettings from "@src/app/components/Elements/Advertisement/AdSettings";
import CatfishAd from "@src/app/components/Elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@src/app/components/Elements/Advertisement/helpers";
import PrestitialAd from "@src/app/components/Elements/Advertisement/PrestitialAd";
import Avatar from "@src/app/components/Elements/Avatar/Avatar";
import GAData from "@src/app/components/Elements/GAData/GAData";
import Spinner from "@src/app/components/Elements/Spinner/Spinner";
import SectionTitle from "@src/app/components/Elements/Typography/SectionTitle/SectionTitle";
import Column from "@src/app/components/Layouts/Column/Column";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import Row from "@src/app/components/Layouts/Row/Row";
import useContributorListingApi from "@src/app/hooks/useContributorListingApi";
import useContributorListingContext from "@src/app/hooks/useContributorListingContext";
import useGlobalAdSetting from "@src/app/hooks/useGlobalAdSetting";
import { Author } from "@src/app/types/Article";
import { logEnd, logStart, removeLeadingSlash } from "@src/app/utils/helpers";
import { useLocation, useSearchParams } from "react-router-dom";

import styles from "./ContributorListing.module.css";

export default function ContributorListingPage() {
  const { isPrestitialEnabled, topOverlayImpressions, isCatfishEnabled } =
    useGlobalAdSetting();

  const tResponse = useContributorListingContext();
  const statusCode = tResponse.statusCode;
  const payload = tResponse.payload;

  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 0;

  const timeStartMain = logStart();
  const { data: apiPayload, isLoading } = useContributorListingApi({
    page,
  });

  logEnd("Contributor useContributorListingApi", timeStartMain);

  if (statusCode != 200 || !payload || typeof payload === "string") {
    return <NotFound />;
  }

  const totalResult =
    apiPayload?.data.totalResults || payload.totalResults || 0;
  const contributors = apiPayload?.data.results || payload.results || [];
  const totalPages = Math.ceil(totalResult / 50);

  return (
    <>
      <AdSettings />

      <MetaTags
        title="Find ThinkChina Editor & ThinkChina Contributors - ThinkChina"
        description="ThinkChina Contributors - Find more information about ThinkChina Team Editors, Economist & more at ThinkChina.sg"
        ogType="article"
        slug={removeLeadingSlash(pathname)}
      />
      <GAData
        title="Contributors"
        level2="contributors"
        contenttype="index/listing page"
      />

      <PrestitialAd
        slot={GoogleAdsSlotFactory.prestitial("authors")}
        isPrestitialEnabled={isPrestitialEnabled}
        topOverlayImpressions={topOverlayImpressions}
      />

      <MainContainer>
        <div className={styles.contributorsContainer}>
          <Row>
            <Column>
              <SectionTitle>Our Contributors</SectionTitle>
              {isLoading ? (
                <Spinner />
              ) : (
                <Row>
                  {contributors.length > 0 &&
                    contributors.map(
                      ({
                        name,
                        url,
                        designation,
                        headshotImageUrl,
                      }: Author) => (
                        <Column key={name} className={styles.col6Tablet}>
                          <div className={styles.editorCard}>
                            <div className={styles.profile}>
                              <div className={styles.photo}>
                                <a
                                  href={url}
                                  aria-label={`View details of ${name}`}
                                >
                                  <div className={styles.image}>
                                    <Avatar
                                      alt={name || ""}
                                      src={headshotImageUrl || ""}
                                    />
                                  </div>
                                </a>
                              </div>
                              <div className={styles.title}>
                                <a
                                  href={url}
                                  aria-label={`Visit ${name}'s profile`}
                                >
                                  <div className={styles.editorName}>
                                    {name}
                                  </div>
                                </a>
                                <div className={styles.editorDesignation}>
                                  {designation}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Column>
                      ),
                    )}
                </Row>
              )}
            </Column>
          </Row>
        </div>

        {isLoading ? null : (
          <div className={styles.pagination}>
            <Pagination isUseQueryParams totalPages={totalPages} />
          </div>
        )}
      </MainContainer>

      <CatfishAd
        slot={GoogleAdsSlotFactory.catfish("authors")}
        isCatfishEnabled={isCatfishEnabled}
      />
    </>
  );
}
