import cx from "classnames";
import type { ReactElement } from "react";

import styles from "./CategoryLabel.module.css";

export type Props = Readonly<{
  href: string;
  label: string;
  transparentBackground?: boolean;
  whiteBackground?: boolean;
}>;

export default function CategoryLabel({
  label,
  href,
  transparentBackground,
  whiteBackground,
}: Props): ReactElement {
  return (
    <div
      className={cx(
        styles.categoryLabel,
        transparentBackground && styles.categoryLabelTransparentBackground,
        whiteBackground && styles.categoryLabelWhiteBackground,
      )}
    >
      <a
        href={href}
        className={styles.link}
        aria-label={`Explore articles in the category: ${label}`}
      >
        {label}
      </a>
    </div>
  );
}
