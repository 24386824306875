import PlaceholderImage from "@src/app/assets/images/placeholder.svg";
import ArticleCardContent from "@src/app/components/Blocks/ArticleCardContent/ArticleCardContent";
import ComicLightbox from "@src/app/components/Blocks/ComicLightbox/ComicLightbox";
import PortraitImageLightBox from "@src/app/components/Blocks/PortraitImageLightBox/PortraitImageLightBox";
// import Advertisement from "@src/app/components/Elements/Advertisement/Advertisement";
// import { GoogleAdsSlotFactory } from "@src/app/components/Elements/Advertisement/helpers";
import KeywordLink from "@src/app/components/Elements/KeywordLink/KeywordLink";
import ResponsiveIframe from "@src/app/components/Elements/ResponsiveIframe/ResponsiveIframe";
import Paragraph from "@src/app/components/Elements/Typography/Paragraph/Paragraph";
import Quotes from "@src/app/components/Elements/Typography/Quotes/Quotes";
import Subheader from "@src/app/components/Elements/Typography/Subheader/Subheader";
import RecommendedArticlesLayout from "@src/app/components/Layouts/RecommendedArticlesLayout/RecommendedArticlesLayout";
import Annotation from "@src/app/components/Modules/ArticlePage/Annotation/Annotation";
import AuthorCard from "@src/app/components/Modules/ArticlePage/AuthorCard/AuthorCard";
import NewsletterWidget from "@src/app/components/Modules/LandingPage/NewsletterWidget/NewsletterWidget";
import {
  ResolvedCUEAnnotation,
  ResolvedCUEElement,
  ResolvedImageCUEElement,
  ResolvedPlainCUEElement,
} from "@src/app/hooks/useArticle/elements";
// import { AdvertisementTypeEnum } from "@src/app/types/Ads.d";
import { ArticleCard, Author } from "@src/app/types/Article";
import { CUElementTypes, CUETags } from "@src/app/types/Opensearch";
import { useEffect, useState } from "react";

import styles from "./Article.module.css";

export function getAuthorNames(authors: Author[]) {
  return authors
    .map((author: Author) => {
      return author.name;
    })
    .join(", ");
}

export function getAuthorCards(authors: Author[]) {
  return authors.map((author: Author) => {
    return (
      <AuthorCard
        key={author.name}
        name={author.name || ""}
        url={author.url || "/"}
        designation={author.designation || ""}
        imageUrl={author.headshotImageUrl || ""}
      />
    );
  });
}

export function getTagNames(tags: CUETags[]) {
  return tags
    .map((tag: CUETags) => {
      return tag.name;
    })
    .join(", ");
}

export function getArticleTagsUrl(tag: CUETags) {
  return "/tags/" + tag.id.split(":").pop();
}

export function getArticleTags(tags: CUETags[]) {
  return tags.map((tag: CUETags) => {
    // sample id: "tag:thinkchina-keywords@sph.com.sg,2022:global-south"
    // split by : and get last string
    const url = getArticleTagsUrl(tag);
    return <KeywordLink key={tag.name} label={tag.name} href={url} />;
  });
}

export function GetRecommendedArticles(recommendedArticles: ArticleCard[]) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const articleCards =
    recommendedArticles.length > 0
      ? recommendedArticles.map((article) => (
          <ArticleCardContent
            key={article.id}
            imgUrl={article.imgUrl}
            imgWidth={article.imgWidth}
            imgHeight={article.imgHeight}
            categoryLabelProps={{
              href: article.category.path,
              label: article.category.name,
            }}
            authors={article.authors}
            articleTitle={article.articleName}
            articlePath={`${article.articlePath}?ref=article-recommended-articles`}
            hasSidePadding={isMobile}
            hasBackground={isMobile}
            hasTitleMargin
            hasCardShadow={false}
            hasAuthorAndDate
            isHero
            hasSnippet={isMobile} // Conditionally render snippet based on screen size
            paragraphContent={article.blurb}
            date=""
            isVideo={article.displayType == "video"}
            displayType={article.displayType}
          />
        ))
      : [];

  return <RecommendedArticlesLayout>{articleCards}</RecommendedArticlesLayout>;
}

function CreateNewsletter(category: string | undefined) {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 991); // Adjust the breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isCulture = category
    ? ["cartoon", "history", "culture"].includes(category)
    : false;

  /* Newsletter Widget */
  const nlHeaderTitle = isCulture
    ? "Get the ThinkCulture Newsletter"
    : "Get the ThinkChina Newsletter";
  const nlBodyText = isCulture
    ? "Your fortnightly dose of Chinese history, culture and cartoons. Sign up now."
    : "Insights on China, right in your mailbox. Sign up now.";

  return isTablet ? (
    <NewsletterWidget bodyText={nlBodyText} headerTitle={nlHeaderTitle} />
  ) : (
    <></>
  );
}

function createParagraph(el: ResolvedCUEElement) {
  return <Paragraph key={el.id}>{createAnnotation(el)}</Paragraph>;
}

function createAnnotation(el: ResolvedCUEElement) {
  // if (!el.annotations) return <></>;
  const annotationContext = el.annotations
    ?.filter(
      (annotation: ResolvedCUEAnnotation) =>
        !!annotation.context && annotation.context.type,
    )
    .map((annotation: ResolvedCUEAnnotation) => annotation.context);

  return (
    annotationContext && (
      <p>
        <Annotation element={el} />
      </p>
    )
  );
}

function constructElementWithLinks(
  value: string | undefined,
  links:
    | {
        index: number;
        length: number;
        type: CUElementTypes | undefined;
        value: string | undefined;
      }[]
    | undefined,
) {
  let constructedElement = "";
  let lastIndex = 0;

  links?.forEach(({ index, length, type, value: linkValue }) => {
    constructedElement += value?.substring(lastIndex, index);

    if (typeof linkValue === "string") {
      // If the link value is a string, it's a simple text link
      const linkText = value?.substring(index, index + length);
      constructedElement += `<a href="${linkValue}" target="${
        type === "internal_link" ? "_self" : "_blank"
      }">${linkText}</a>`;
    } else {
      // If the link value is an element, it's a nested element
      constructedElement += value?.substring(index, index + length);
    }

    lastIndex = index + length;
  });

  // Append the remaining part of the value
  constructedElement += value?.substring(lastIndex);
  return constructedElement;
}

function getLinksFromAnnotations(
  annotations: ResolvedCUEAnnotation[] | undefined,
) {
  return annotations?.map((annotation) => {
    console.log("annotation", annotation);
    const link = annotation.context?.relation?.urlPath;
    return {
      index: annotation.index,
      length: annotation.length,
      type: annotation.context?.type,
      value: link,
    };
  });
}

function createImage(el: ResolvedImageCUEElement) {
  const elementRelation = el.relation;
  const inlineImage =
    elementRelation?.fields?.["original-caas"] ??
    elementRelation?.fields?.["original"];
  const inlineImageUrl = inlineImage?.url ?? PlaceholderImage;

  const captionElement = el.fields?.caption;
  const captionAnnotations = el.fields?.caption?.annotations;
  const captionLinks = getLinksFromAnnotations(captionAnnotations);

  const creditElement = el.fields?.credit;
  const creditAnnotations = el.fields?.credit?.annotations;
  const creditLinks = getLinksFromAnnotations(creditAnnotations);

  const altElement = el.fields?.altText;

  const captionWithCredit =
    (captionElement
      ? constructElementWithLinks(captionElement.value, captionLinks)
      : "") +
    (creditElement
      ? ` (${constructElementWithLinks(creditElement.value, creditLinks)})`
      : "");

  const inlineImageCaption = captionWithCredit;
  const inlineImageWidth = inlineImage?.width ?? 900;
  const inlineImageHeight = inlineImage?.height ?? 600;

  const captionNode = captionElement ? (
    <Annotation element={captionElement} />
  ) : null;

  const creditNode = creditElement ? (
    <Annotation element={creditElement} />
  ) : null;

  const captionNodeWithCreditNode = (
    <>
      <span>{captionNode}</span>
      {creditNode ? <span> ({creditNode})</span> : null}
    </>
  );

  return (
    <PortraitImageLightBox
      src={inlineImageUrl}
      alt={altElement?.value || inlineImageCaption}
      caption={captionNodeWithCreditNode}
      srcWidth={inlineImageWidth}
      srcHeight={inlineImageHeight}
      testId="portrait-image-light-box"
    />
  );
}

function createList(el: ResolvedPlainCUEElement, listType: string) {
  const elementRelation = el.children || [];

  // Extract the 'value' and 'annotations' properties from each object
  const valuesArray = elementRelation.map((element) => ({
    value: element.value,
    annotations: element.annotations || [],
  }));

  // Render a list based on the specified type
  const ListComponent = listType === "bulleted" ? "ul" : "ol";

  // Conditionally render the list based on whether there are items in valuesArray
  return (
    <>
      {valuesArray.length > 0 && (
        <ListComponent
          className={
            listType === "bulleted" ? styles.bulletedlist : styles.numberedlist
          }
        >
          {valuesArray.map((item, index) => (
            <li key={index} className={styles.listItem}>
              {createAnnotation(item as ResolvedCUEElement)}
            </li>
          ))}
        </ListComponent>
      )}
    </>
  );
}

function createSubhead(el: ResolvedPlainCUEElement) {
  return (
    <Subheader>
      {el.annotations ? createAnnotation(el) : el.fields?.subhead?.value}
    </Subheader>
  );
}

function createPullQuote(el: ResolvedPlainCUEElement) {
  // Use nullish coalescing for fields, quote, and value

  return (
    <Quotes>
      <Annotation
        element={{
          annotations: el.fields?.quote.annotations || [],
          value: el.fields?.quote.value || "",
        }}
      />
    </Quotes>
  );
}

function createEmbed(el: ResolvedPlainCUEElement) {
  return <ResponsiveIframe src={el.fields?.uri?.value ?? ""} hasMargin />;
}

/*
function createAdvertisement(
  type: "imu1" | "midarticlespecial",
  category: string,
) {
  let ads = <></>;

  switch (type) {
    case "imu1":
      ads = (
        <Advertisement
          adUnitProps={{
            type: AdvertisementTypeEnum.IMU1,
            slot: GoogleAdsSlotFactory.imu1(category),
          }}
        />
      );
      break;
    case "midarticlespecial":
      ads = (
        <Advertisement
          adUnitProps={{
            type: AdvertisementTypeEnum.MIDARTICLESPECIAL,
            slot: GoogleAdsSlotFactory.midarticlespecial(category),
          }}
        />
      );
      break;
  }

  return ads;
}
*/

export function getArticleBodyComic(bodyElements: ResolvedCUEElement[]) {
  const images: Array<{
    src: string;
    alt: string;
    width: number;
    height: number;
  }> = [];

  bodyElements.map((el: ResolvedCUEElement) => {
    if (el.type === "image") {
      const elementRelation = el.relation;
      const image =
        elementRelation?.fields?.["original-caas"] ??
        elementRelation?.fields?.["original"];
      const imageUrl = image?.url ?? PlaceholderImage;
      const imageCaption = el.fields?.caption?.value ?? "";
      const imageWidth = image?.width ?? 1200;
      const imageHeight = image?.height ?? 1698;

      images.push({
        src: imageUrl,
        alt: imageCaption,
        width: imageWidth,
        height: imageHeight,
      });
    }
  });

  return <ComicLightbox id="comicLightbox" images={images} />;
}

export function getArticleBody(
  bodyElements: ResolvedCUEElement[],
  category: string | undefined,
  isFeaturedArticle: boolean | undefined,
  isArticleAComic: boolean | undefined,
) {
  const midArticleCount = Math.ceil(bodyElements.length / 2);
  // let paragraphCount = 0;

  return bodyElements.map((el: ResolvedCUEElement, index) => {
    let element = <></>;

    switch (el.type) {
      case "paragraph": {
        const ad_element = <></>;
        // paragraphCount++;

        // append newsletter in the mid of paragraph

        /*
          // TODO: leaving this code for future use;
          // editorial team is still deciding whether or not to add this
          paragraphCount++;
          // show IMU1 ad after first 3 paragraphs
          if (paragraphCount === 3) {
            ad_element = createAdvertisement("imu1", category);
          }
          // show midarticlespecial ad after next 3 paragraphs
          else if (paragraphCount === 6) {
            ad_element = createAdvertisement("midarticlespecial", category);
          }
        */

        element = (
          <>
            {createParagraph(el)}
            {ad_element}
          </>
        );
        break;
      }
      case "image":
        element = createImage(el);
        break;
      case "sub_head":
        element = createSubhead(el);
        break;
      case "pull_quote":
        element = createPullQuote(el);
        break;
      case "list_bulleted":
        element = createList(el, "bulleted");
        break;
      case "list_numbered":
        element = createList(el, "numbered");
        break;
      case "embed":
        element = createEmbed(el);
        break;
    }

    console.log(
      "!isFeaturedArticle || !isArticleAComic::",
      !isFeaturedArticle && !isArticleAComic,
    );

    // append newsletter in mid article
    // only applicable to default and video article
    if (index === midArticleCount && !isFeaturedArticle && !isArticleAComic) {
      return (
        <div key={index}>
          {element}
          {CreateNewsletter(category)}
        </div>
      );
    }

    return <div key={index}>{element}</div>;
  });
}

export async function getGSChannels(
  pagePath: string,
): Promise<{ grapeshot: string; fr: string }> {
  const domain =
    (import.meta.env.VITE_BASE_URL as string) || "https://www.thinkchina.sg";

  const iasUrl = (import.meta.env.VITE_IAS_API_ENDPOINT as string) || "";

  const pageUrl = encodeURIComponent(`${domain}${pagePath}`);
  const grapeshotUrl = `${iasUrl}${pageUrl}`;
  // const grapeshotUrl = `https://sph.gscontxt.net/main/channels.cgi?url=${pageUrl}`;
  // const grapeshotUrl = `https://uatnewsapi.sphdigital.com/v2/cc-ivt/web?url=https://uat.zaobao.com.sg/realtime/singapore/story20240808-1225904`;

  let grapeshot = "";
  let fr = "";

  console.log(18288299, grapeshotUrl);
  try {
    const response = await fetch(grapeshotUrl, {
      signal: AbortSignal.timeout(5000),
      method: "GET",
    });
    const result = (await response.json()) as Partial<{
      channels: { name: string }[];
      fr: string;
    }>;
    const value = (result.channels || []).map((i) => i.name).join(",");

    fr = result.fr || "";

    console.log(value, 18288299, grapeshotUrl, result);

    if (["no_analysis", "no_category"].includes(value)) {
      grapeshot = "";
      return { grapeshot, fr };
    }

    return { grapeshot: value, fr };
  } catch (e: unknown) {
    console.error(e);
  }

  return { grapeshot, fr };
}
