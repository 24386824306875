import ImageTag from "@src/app/components/Elements/ImageTag/ImageTag";
import ArticleHeader from "@src/app/components/Elements/Typography/ArticleHeader/ArticleHeader";
import type { ReactElement } from "react";

export interface IContentTop {
  headline: string;
  displayType: string;
  testId?: string;
  isFullWidthArticle?: boolean;
}

export default function ContentTop({
  headline,
  displayType,
  testId = "content-top",
  isFullWidthArticle,
}: IContentTop): ReactElement {
  return (
    <>
      <div data-testid={testId}>
        {isFullWidthArticle && displayType != "comic" && (
          <ImageTag type={displayType} redBackground alignCenter />
        )}
        <ArticleHeader isLarger={isFullWidthArticle}>{headline}</ArticleHeader>
      </div>
    </>
  );
}
