import SignupForm from "@src/app/components/Blocks/NewsletterSignupForm/NewsletterSignupForm";
import cx from "classnames";
import type { ReactElement } from "react";

import styles from "./NewsletterWidget.module.css";

type Props = Readonly<{
  headerTitle: string;
  bodyText: string;
  testId?: string;
}>;

export default function NewsletterWidget({
  testId,
  headerTitle,
  bodyText,
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={cx(styles.newsletterWidgetContainer)}>
      <div
        data-testid={`${testId}-innerContainer`}
        className={cx(styles.innerContainer)}
      >
        <div className={styles.emptyDiv}></div>
        <div className={styles.contentContainer}>
          <div
            data-testid={`${testId}-headerContainer`}
            className={styles.headerContainer}
          >
            <div
              data-testid={`${testId}-headerTitle`}
              className={styles.headerTitle}
            >
              {headerTitle}
            </div>
          </div>

          <div
            data-testid={`${testId}-formContainer`}
            className={styles.formContainer}
          >
            <div data-testid={`${testId}-bodyText`} className={styles.bodyText}>
              {bodyText}
            </div>
            <SignupForm />
          </div>
        </div>
      </div>
    </div>
  );
}
