import cx from "classnames";
import React from "react";

import styles from "./ResponsiveIframe.module.css";
import { convertToEmbed } from "./utils";

const ResponsiveIframe: React.FC<{
  /** the url of the iframe, can automatically convert common video urls into embed urls, will also guess aspect ratio based off this unless srcWidth and srcHeight is given */
  src: string;
  /** the width of the content referenced in src, used to generate a the aspect ratio  */
  srcWidth?: number;
  /** the height of the content referenced in src, used to generate a the aspect ratio  */
  srcHeight?: number;
  /** iframe title attribute */
  title?: string;
  /** loading for browser nativ lazy loading */
  loading?: "lazy" | "eager";
  hasMargin?: boolean;
}> = ({ title, src, hasMargin }) => {
  const isShorts = src.includes("youtube.com/shorts");
  const aspectRatio = isShorts
    ? 9 / 16
    : src.includes("youtu.be") || src.includes("youtube") /* ||
      src.includes("brightcove") */
      ? 16 / 9
      : 4 / 6;
  return (
    <div
      className={cx(styles.responsiveIframe, {
        [styles.isVerticle]: isShorts,
        [styles.hasMargin]: hasMargin,
      })}
    >
      <iframe
        className={styles.base}
        title={title}
        src={convertToEmbed(src)}
        style={{
          aspectRatio,
        }}
        allowFullScreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
      />
    </div>
  );
};

export default ResponsiveIframe;
