import ArticleCardContent from "@src/app/components/Blocks/ArticleCardContent/ArticleCardContent";
import MetaTags from "@src/app/components/Blocks/MetaTags/MetaTags";
import NotFound from "@src/app/components/Blocks/NotFound/NotFound";
import Pagination from "@src/app/components/Blocks/Pagination/Pagination";
import AdSettings from "@src/app/components/Elements/Advertisement/AdSettings";
import CatfishAd from "@src/app/components/Elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@src/app/components/Elements/Advertisement/helpers";
import PrestitialAd from "@src/app/components/Elements/Advertisement/PrestitialAd";
import GAData from "@src/app/components/Elements/GAData/GAData";
import Spinner from "@src/app/components/Elements/Spinner/Spinner";
import SectionTitle from "@src/app/components/Elements/Typography/SectionTitle/SectionTitle";
import Column from "@src/app/components/Layouts/Column/Column";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import Row from "@src/app/components/Layouts/Row/Row";
import StandardCardLayout from "@src/app/components/Layouts/StandardCardLayout/StandardCardLayout";
import formatPublishedDate from "@src/app/helper/formatPublishedDate";
import useGlobalAdSetting from "@src/app/hooks/useGlobalAdSetting";
import useSectionListingApi from "@src/app/hooks/useSectionListingApi";
import useSectionListingContext from "@src/app/hooks/useSectionListingContext";
import {
  capitalizeFirstLetter,
  logEnd,
  logStart,
  removeLeadingSlash,
} from "@src/app/utils/helpers";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import styles from "./SectionListing.module.css";

export default function SectionListingPage() {
  const tResponse = useSectionListingContext();
  const statusCode = tResponse.statusCode;
  const payload = tResponse.payload;

  const { topic } = useParams();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  // format spacing in URL
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 0;
  const timeStart = logStart();
  const { data: searchPayload, isLoading } = useSectionListingApi({
    topicUniqueName: topic || "",
    page,
  });
  logEnd("SectionListing useSectionListingApi", timeStart);

  const targeting = [
    {
      key: "page",
      value: "listing",
    },
    {
      key: "page_number",
      value: `${page + 1}`,
    },
  ];

  const { isPrestitialEnabled, topOverlayImpressions, isCatfishEnabled } =
    useGlobalAdSetting({
      targeting,
    });

  if (statusCode != 200 || !payload || typeof payload === "string") {
    return <NotFound />;
  }

  const displayedTopic = capitalizeFirstLetter(topic as string);

  const articles = searchPayload?.data.results || payload.articles || [];

  const totalResult =
    searchPayload?.data.totalResults || payload.totalResult || 0;

  // divide total card display in a page to get totalPages
  const totalPages = Math.ceil(totalResult / 12);

  return (
    <>
      <AdSettings />

      <MetaTags
        title={`Latest China ${displayedTopic} News & Headlines, Top ${displayedTopic} News - ThinkChina`}
        description={`${displayedTopic} News - Get latest ${displayedTopic} news today in China & Greater China. Find top ${displayedTopic} stories in China & Greater China at thinkchina.sg`}
        ogType="article"
        slug={removeLeadingSlash(pathname)}
      />
      <GAData
        title={displayedTopic}
        level2={topic}
        contenttype="index/listing page"
        pagination={page}
      />

      <PrestitialAd
        slot={GoogleAdsSlotFactory.prestitial(topic)}
        isPrestitialEnabled={isPrestitialEnabled}
        topOverlayImpressions={topOverlayImpressions}
      />

      <MainContainer>
        <div className={styles.sectionListing}>
          <Row>
            <Column>
              <SectionTitle>{displayedTopic}</SectionTitle>
            </Column>
          </Row>

          <Row>
            <Column>
              {isLoading ? (
                <Spinner />
              ) : (
                <div className={styles.standardCardLayoutContainer}>
                  <StandardCardLayout>
                    {articles.map((article) => (
                      <ArticleCardContent
                        key={article.id}
                        articleTitle={article.articleName}
                        articlePath={article.articlePath}
                        authors={article.authors}
                        categoryLabelProps={{
                          href: article.category.path,
                          label: article.category.name,
                        }}
                        date={formatPublishedDate(article.publishedDate)}
                        hasAuthorAndDate
                        hasBackground
                        hasSidePadding
                        hasSnippet
                        hasTitleMargin
                        imgUrl={article.imgUrl}
                        imgWidth={article.imgWidth}
                        imgHeight={article.imgHeight}
                        paragraphContent={article.blurb}
                        isVideo={article.displayType == "video"}
                        displayType={article.displayType}
                      />
                    ))}
                  </StandardCardLayout>
                </div>
              )}
            </Column>
          </Row>

          <Row>
            <Column>
              {isLoading ? null : (
                <div className={styles.paginationContainer}>
                  <Pagination isUseQueryParams totalPages={totalPages} />
                </div>
              )}
            </Column>
          </Row>
        </div>
      </MainContainer>

      <CatfishAd
        slot={GoogleAdsSlotFactory.catfish(topic)}
        isCatfishEnabled={isCatfishEnabled}
      />
    </>
  );
}
