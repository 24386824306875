import cx from "classnames";
import type { ReactElement } from "react";

import styles from "./BlockTitle.module.css";

type Props = Readonly<{
  children: string;
  hasBorder?: boolean;
  testId?: string;
}>;

export default function BlockTitle({
  children,
  hasBorder,
  testId,
}: Props): ReactElement {
  return (
    <h2
      data-testid={testId}
      className={cx(styles.blockTitle, hasBorder && styles.blockTitleBorder)}
    >
      <span>{children}</span>
    </h2>
  );
}
