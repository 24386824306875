import cx from "classnames";
import { ReactElement, ReactNode } from "react";

import styles from "./Row.module.css";

export type Props = Readonly<{
  testId?: string;
  isVCenter?: boolean;
  isReverse?: boolean;
  noGutters?: boolean;
  children: ReactNode;
}>;

export default function Row({
  children,
  isVCenter = false,
  noGutters = false,
  isReverse = false,
  testId = "row",
}: Props): ReactElement {
  return (
    <div
      data-testid={testId}
      className={cx(
        styles.row,
        isVCenter && styles.center,
        isReverse && styles.isReverse,
        noGutters && styles.noGutters,
      )}
    >
      {children}
    </div>
  );
}
