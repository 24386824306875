import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BackgroundPlaceholderImage from "@src/app/assets/images/editors-pick-placeholder.svg";
import PlaceholderImage from "@src/app/assets/images/placeholder.svg";
import CategoryLabel, {
  Props as CategoryLabelProps,
} from "@src/app/components/Elements/CategoryLabel/CategoryLabel";
import ResponsiveImage from "@src/app/components/Elements/ResponsiveImage/ResponsiveImage";
import useWindowDimensions from "@src/app/hooks/useWindowDimensions";
import cx from "classnames";
import { type ReactElement, useEffect, useRef, useState } from "react";

import styles from "./EditorsPick.module.css";

export type Props = Readonly<{
  articleUrl: string;
  authorImgUrls: string[];
  authorNames: string[];
  authorUrls: string[];
  backgroundImg: string;
  backgroundImgAlt: string;
  categoryLabelProps: CategoryLabelProps;
  // editorsPickTitle?: string; // Optional if needed
  headline: string;
  paragraphContent: string;
  //translator: string;
  testId?: string;
}>;

export default function EditorsPick({
  articleUrl,
  authorImgUrls,
  authorNames,
  authorUrls,
  backgroundImg,
  backgroundImgAlt,
  categoryLabelProps,
  // editorsPickTitle,
  headline,
  paragraphContent,
  //translator,
  testId = "editors-pick",
}: Props): ReactElement {
  const sendDataToGTM = useGTMDispatch();
  const editorsPick = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const dimensions = useWindowDimensions();
  const isTablet = dimensions.width >= 992;

  useEffect(() => {
    let observerRefValue: HTMLDivElement | null = null;

    const options = {};

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isVisible) {
          // Call sendDataToGTM method when the div becomes visible
          sendDataToGTM({
            event: "tcCustomEvent",
            eventCategory: "editors pick",
            eventLabel: "editors pick",
            eventAction: "view",
          });
          setIsVisible(true);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    if (editorsPick.current) {
      observer.observe(editorsPick.current);
      observerRefValue = editorsPick.current; // <-- save ref value
    }

    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue); // <-- use saved value
    };
  }, [isVisible, sendDataToGTM]);

  return (
    <div
      ref={editorsPick}
      className={cx(styles.editorsPickContainer, isVisible && styles.active)}
      data-testid={testId}
      style={{
        backgroundImage: `url(${backgroundImg || BackgroundPlaceholderImage})`,
      }}
    >
      {/* <SectionTitle>{editorsPickTitle}</SectionTitle> */}
      <div className={cx(styles.contentWrapper)}>
        {!isTablet && (
          <div className={styles.featureImageContainer}>
            <img
              src={backgroundImg || BackgroundPlaceholderImage}
              alt={backgroundImgAlt}
            />
          </div>
        )}

        <div className={styles.headerContainer}>
          <div className={styles.headlineContainer}>
            <CategoryLabel
              {...categoryLabelProps}
              whiteBackground={!isTablet}
            />
            <a href={articleUrl} className={cx(styles.headline)}>
              {headline}
            </a>
          </div>

          <div className={cx(styles.authorContainer)}>
            <div className={cx(styles.authorsWrapper)}>
              {authorNames.map((authorName, index) => (
                <a
                  href={authorUrls[index] || "#"}
                  className={cx(styles.author)}
                  key={index}
                >
                  <ResponsiveImage
                    className={styles.avatar}
                    src={authorImgUrls[index] || PlaceholderImage}
                    alt={authorName}
                    srcWidth={40}
                    srcHeight={40}
                    loading="lazy"
                    displayWidth={40}
                  />
                  <p className={styles.name}>By {authorName}</p>
                </a>
              ))}
            </div>
            {/*
            {translator && (
              <p className={styles.translator}>Translated by {translator}</p>
            )} */}

            <div className={cx(styles.authorContainerText)}>
              <p className={cx(styles.paragraphContent)}>{paragraphContent}</p>
              <a href={articleUrl} data-testid={`${testId}-read-more`}>
                <span>READ MORE</span>
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.overlay}></div>
    </div>
  );
}
