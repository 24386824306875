import { GTMProvider, useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import validateAndSanitizeTerm from "@src/app/helper/validateAndSanitizeTerm";
import { ReactElement, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import sanitizeHtml from "sanitize-html";

import { GTM_ID } from "./constants";

type GADataProps = {
  articleid?: string;
  cue_articleid?: string;
  at?: string;
  author?: string;
  chapter1?: string;
  contentcat?: string;
  contenttype?: "index/listing page" | "article" | undefined;
  keyword?: string;
  level2?: string;
  pagination?: number;
  permutive_id?: string;
  pubdate?: string;
  title?: string;
  visitorcat?: 1 | 2 | undefined;
  cd_version?: "" | "tc2" | undefined;
  grapeshot?: string;
};

/**
 * GAData
 * Description: This component renders the _data layer_ for GA to consume.
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function GAData(props: GADataProps): ReactElement {
  const {
    articleid = "",
    cue_articleid = "",
    at = "", // mysphw cookie
    author = "", // Comma separated author name
    chapter1 = "", // Section child category name.
    contentcat = "free", // Paid (2) or free (1)
    contenttype = "", // index/listing, article
    keyword = "", // Comma separated keyword names
    // if in article, level2 = main category parent or main category, main category is the first category
    // if breakingnews, level2 = breaking news
    level2 = "",
    pagination = 1,
    permutive_id = "",
    pubdate = "", // Post date of article otherwise ""
    title = "", // can contain spaces
    visitorcat = 1,
    cd_version = "tc2",
    grapeshot = "",
  } = props;
  const fallbackId = cue_articleid ? cue_articleid : articleid;

  const sendDataToGTM = useGTMDispatch();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  useEffect(() => {
    if (typeof window._data != "undefined") {
      const permutive_id = validateAndSanitizeTerm(
        window.localStorage.getItem("permutive-id") || "",
      );
      window._data = {
        ...window._data,
        articleid: fallbackId,
        cue_articleid,
        at,
        author,
        chapter1,
        contentcat,
        contenttype,
        keyword,
        level2,
        pagination,
        page: pagination,
        permutive_id,
        pubdate,
        title,
        visitorcat,
        cd_version,
      };
      window.isArticlePage = !!window._data.articleid;
    }

    // const gaDataScriptId = "ga_data_layer";
    //     if (
    //       typeof window !== "undefined" &&
    //       !document.getElementById(gaDataScriptId)
    //     ) {
    //       const permutive_id = validateAndSanitizeTerm(
    //         window.localStorage.getItem("permutive-id") || "",
    //       );
    //       const gaData = `
    // var _data = {};
    // _data = {
    //   "articleid"      : "${fallbackId}",
    //   "cue_articleid"  : "${cue_articleid}",
    //   "at"             : "${at}",
    //   "author"         : "${author}",
    //   "chapter1"       : "${chapter1}",
    //   "contentcat"     : "${contentcat}",
    //   "contenttype"    : "${contenttype}",
    //   "keyword"        : "${keyword}",
    //   "level2"         : "${level2}",
    //   "pagination"     : "${pagination}",
    //   "page"           : "${pagination}",
    //   "permutive_id"   : "${permutive_id}",
    //   "pubdate"        : "${pubdate}",
    //   "title"          : "${title}",
    //   "visitorcat"     : "${visitorcat}",
    //   "cd_version"     : "${cd_version}",
    // };
    // var isArticlePage = false;
    // if (typeof _data != "undefined" && typeof _data.articleid != "undefined" && _data.articleid != "") {
    //   isArticlePage = true;
    // }
    //           `;
    //       const sanitizedGaData: string = DOMPurify.sanitize(gaData);
    //       const gaDataScript = document.createElement("script");

    //       gaDataScript.id = gaDataScriptId;
    //       gaDataScript.dataset.testid = gaDataScriptId;
    //       gaDataScript.innerHTML = sanitizedGaData;
    //       gaDataScript.type = "text/javascript";
    //       document.body.appendChild(gaDataScript);
    //     }

    if (!isLoaded) {
      sendDataToGTM({
        event: "tcCustomPageView",
        articleid: fallbackId,
        author: author,
        cd_version: cd_version,
        level2: level2,
        chapter1: chapter1,
        contentcat: contentcat,
        contenttype: contenttype,
        cue_articleid: cue_articleid,
        keyword: keyword,
        pubdate: pubdate,
        title: title,
        visitorcat: visitorcat,
        at: at,
      });
      setIsLoaded(true);
    }
  }, [
    isLoaded,
    sendDataToGTM,
    at,
    author,
    cd_version,
    chapter1,
    contentcat,
    contenttype,
    cue_articleid,
    fallbackId,
    keyword,
    level2,
    pagination,
    permutive_id,
    pubdate,
    title,
    visitorcat,
  ]);

  return (
    <>
      <Helmet prioritizeSeoTags htmlAttributes={{ "data-react-helmet": true }}>
        <script id="ga_data_layer">
          {`
          var gs_channels = "${sanitizeHtml(grapeshot)}";
          var _data = {};
          _data = {
            "articleid"      : "${sanitizeHtml(fallbackId)}",
            "cue_articleid"  : "${sanitizeHtml(cue_articleid)}",
            "at"             : "${sanitizeHtml(at)}",
            "author"         : "${sanitizeHtml(author)}",
            "chapter1"       : "${sanitizeHtml(chapter1)}",
            "contentcat"     : "${sanitizeHtml(contentcat)}",
            "contenttype"    : "${sanitizeHtml(contenttype)}",
            "keyword"        : "${sanitizeHtml(keyword)}",
            "level2"         : "${sanitizeHtml(level2)}",
            "pagination"     : "${pagination}",
            "page"           : "${pagination}",
            "permutive_id"   : "${sanitizeHtml(permutive_id)}",
            "pubdate"        : "${sanitizeHtml(pubdate)}",
            "title"          : "${sanitizeHtml(title)}",
            "visitorcat"     : "${visitorcat}",
            "cd_version"     : "${sanitizeHtml(cd_version)}",
          };
          var isArticlePage = false;
          if (typeof _data != "undefined" && typeof _data.articleid != "undefined" && _data.articleid != "") {
            isArticlePage = true;
          }
          `}
        </script>
      </Helmet>

      <GTMProvider
        state={{
          id: GTM_ID,
          dataLayer: {
            title: title,
            level2: level2,
            contenttype: contenttype,
            articleid: fallbackId,
            author: author,
            keyword: keyword,
          },
        }}
      >
        <></>
      </GTMProvider>
    </>
  );
}
