import "./styles/index.css";

import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { RenderContext } from "@sphtech/web2-core/ssr";
import { GTM_ID } from "@src/app/components/Elements/GAData/constants";
import PageLayout from "@src/app/components/Layouts/PageLayout/PageLayout";
import HeadScriptProvider from "@src/app/providers/HeadScriptProvider";
import NeuronProvider from "@web2/providers/NeuronProvider";
import NewRelic from "@web2/providers/NewRelic";
import { ReactElement, StrictMode, useContext } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Outlet, ScrollRestoration } from "react-router-dom";

export default function App(): ReactElement {
  const renderContext = useContext(RenderContext);

  return (
    <StrictMode>
      <HeadScriptProvider />
      <NeuronProvider>
        <ScrollRestoration />
        <HelmetProvider context={renderContext.helmet}>
          <GTMProvider state={{ id: GTM_ID }}>
            <PageLayout>
              <>
                <NewRelic />
                <Outlet />
              </>
            </PageLayout>
          </GTMProvider>
        </HelmetProvider>
      </NeuronProvider>
    </StrictMode>
  );
}
