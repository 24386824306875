import imgCandiceChan from "@src/app/assets/images/editors/candice-chan.svg";
import imgCharleneChow from "@src/app/assets/images/editors/charlene-chow.svg";
import imgChowYianPing from "@src/app/assets/images/editors/chow-yian-ping.svg";
import imgGraceChong from "@src/app/assets/images/editors/grace-chong.svg";
import imgJamesLoo from "@src/app/assets/images/editors/james-loo.svg";
import imgYiJina from "@src/app/assets/images/editors/jina.svg";
import imgJosephineHong from "@src/app/assets/images/editors/josephine-hong.svg";
import imgLingmingLu from "@src/app/assets/images/editors/lingming-lu.svg";
import imgLohWoonYen from "@src/app/assets/images/editors/loh-woon-yen.svg";

export const editors = [
  {
    name: "Loh Woon Yen",
    bio: "Woon Yen is the Managing Editor of Chinese Media Group in SPH Media, where she heads the teams responsible for newsroom operations, partnership and engagement, as well as custom publishing. Prior to this, she was the Deputy Digital Editor of the Chinese Media Group and the Business Editor of Lianhe Zaobao. Woon Yen studied Accounting and Finance at Lancaster University and is a Chartered Financial Analyst.",
    designation: "Managing Editor (Chinese Media Group)",
    image: imgLohWoonYen,
  },
  {
    name: "Chow Yian Ping",
    bio: 'Yian Ping is the founding editor of ThinkChina. She was a senior correspondent at Lianhe Zaobao (LHZB) where she wrote big reads and features. She has worked as a journalist, a museum curator and a TV producer while maintaining a <a target="_blank" href="https://www.zaobao.com.sg/byline/zhou-yan-bing">regular column</a> in LHZB for over 20 years. She has lived in various cities, including Beijing, Hong Kong, Manila, Wellington and Singapore. She also oversaw LHZB\'s <a target="_blank" href="https://interactive.zaobao.com/zaobao-centennial/oral-history/index.html">oral history</a> project celebrating the paper\'s centennial.',
    designation: "Editor",
    image: imgChowYianPing,
  },
  {
    name: "Charlene Chow",
    bio: "Charlene Chow is the Assistant Editor at ThinkChina. She was previously with the Singapore Foreign Service where she worked at various desks and was posted abroad. She later joined a food magazine where she produced features, personality profiles and other print and online stories. She enjoys exploring the worlds of people, culture and food.",
    designation: "Assistant Editor",
    image: imgCharleneChow,
  },
  {
    name: "Candice Chan",
    bio: "Candice was previously a publishing editor and civil servant. She loves words and languages, which prompted her to learn French in NUS and study translation in NTU. She also enjoys books, music, movies, and photographs, all of which tell stories in their own way.",
    designation: "Executive Translator/Content Producer",
    image: imgCandiceChan,
  },
  {
    name: "Grace Chong",
    bio: "Grace pursued her Master's degree in English linguistics right after she completed her Bachelor's degree in Chinese linguistics. She is intrigued by how linguistic theories can be applied across different languages, and if they can't, they'll make good research papers. When she's not translating, she can be found singing, eating, and shopping.",
    designation: "Translator/Content Producer",
    image: imgGraceChong,
  },
  {
    name: "James Loo",
    bio: "James was previously a translator and lecturer at a university in Taiwan and had also worked as a translator for a think tank in Taiwan. He holds a Master's in Translation and Interpretation from National Taiwan Normal University and a Bachelor's degree in English Literature from Nanyang Technological University in Singapore. He continues to be fascinated by how translation can be a metaphor for metaphor, and how metaphor can be a metaphor for translation.",
    designation: "Translator/Content Producer",
    image: imgJamesLoo,
  },
  {
    name: "Josephine Hong",
    bio: "Josephine completed her undergraduate degree in Chinese Language and Literature from Fudan University, Shanghai, and is an accredited editor with the Institute of Professional Editors (Australia and New Zealand). She has edited for a trade publisher, government organisation and finance and economics research houses. She is passionate about the art of storytelling in both digital and print mediums.",
    designation: "Copy Editor",
    image: imgJosephineHong,
  },
  {
    name: "Lu Lingming",
    bio: "Lu Lingming earned a Master’s degree in Directing from the School of Visual Arts New York. A passionate storyteller with a sharp eye for visual creativity, he has directed independent and commercial projects, winning Best Original Story at the London Short Film Festival and Best Student Director at the Independent Shorts Awards. Outside of filmmaking, Lingming finds inspiration by attending music festivals and discovering hidden spots around the world.",
    designation: "Video Journalist",
    image: imgLingmingLu,
  },
  {
    name: "Yi Jina",
    bio: "Jina graduated from the Wee Kim Wee School of Communication and Information, where she refined her skills in storytelling through videos. She is passionate about leveraging social media and video content to engage and inform the younger demographic. Beyond video production, Jina finds joy in expressing herself through dance and eating spicy food.",
    designation: "Video Journalist",
    image: imgYiJina,
  },
];
