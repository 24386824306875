import SignupForm from "@src/app/components/Blocks/NewsletterSignupForm/NewsletterSignupForm";
import type { ReactElement } from "react";

import styles from "./NewsletterWidget.module.css";

type Props = Readonly<{
  isThinkCulture?: boolean;
  headerTitle: string;
  bodyText: string;
  testId?: string;
  icon: string;
}>;

export default function NewsletterWidget({
  isThinkCulture,
  testId,
  headerTitle,
  bodyText,
  icon,
}: Props): ReactElement {
  const newsletterWidgetContainer = icon
    ? styles.newsletterWidgetContainer
    : styles.footerNewletter;

  return (
    <div data-testid={testId} className={newsletterWidgetContainer}>
      <div>
        {icon ? (
          <div data-testid={`${testId}-iconContainer`}>
            <img
              data-testid={`${testId}-thinkCultureIcon`}
              src={icon}
              alt="ThinkCulture Icon"
            />
          </div>
        ) : null}
        <h4 data-testid={`${testId}-headerTitle`}>{headerTitle}</h4>
        <p data-testid={`${testId}-bodyText`}>{bodyText}</p>
      </div>

      <SignupForm isThinkCulture={isThinkCulture} isLarger />
    </div>
  );
}
