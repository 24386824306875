import { useScript } from "@sphtech/web2-core/hooks";
import {
  permutiveScript,
  permutiveSphl,
} from "@src/app/components/Elements/Advertisement/scripts/permutive";
import useLink from "@src/app/hooks/useLink";
import useScriptFunction from "@src/app/hooks/useScriptFunction";
import dfpPreview from "@src/app/providers/scripts/dfpPreview";
import {
  facebookEventScript,
  facebookNoScript,
} from "@src/app/providers/scripts/facebook";
import hotjarTrackingCode from "@src/app/providers/scripts/hotjarTrackingCode";
import {
  vwoPreconnect,
  vwoSmartcode,
} from "@src/app/providers/scripts/vwoCode";

import {
  linkedinPixelCode_noScript,
  linkedinPixelCode_script,
} from "./scripts/linkedinPixelCode";
import { outbrainPixelCode } from "./scripts/outbrainPixelCode";
import { twitterPixelCode } from "./scripts/twitterPixelCode";

export default function HeadScriptProvider() {
  // DFP Preview
  useScriptFunction({
    id: "dfp-preview",
    value: dfpPreview,
  });

  // Edge Permutive App
  useScript({
    id: "edge-permutive-app",
    src: permutiveScript,
  });
  // Permutive SPHL
  useScriptFunction({
    id: "permutive-sphl",
    value: permutiveSphl,
  });

  // Facebook Event Tracking
  useScriptFunction({ id: "facebook-script", value: facebookEventScript });
  useScriptFunction({
    id: "facebook-no-script",
    value: facebookNoScript,
    noscript: true,
    portion: "body",
  });

  // Hotjar Tracking Code
  useScriptFunction({
    id: "hotjar-tracking-code",
    value: hotjarTrackingCode,
  });

  // VWO Smartcode
  useLink({
    id: "vwo-smartcode-preconnect",
    rel: "preconnect",
    href: vwoPreconnect,
  });
  useScriptFunction({
    id: "vwo-smartcode",
    value: vwoSmartcode,
  });

  // outbrain pixel
  useScriptFunction({
    id: "outbrain-pixel",
    value: outbrainPixelCode,
  });

  // linkedin pixel script
  useScriptFunction({
    id: "linkedin-pixel",
    value: linkedinPixelCode_script,
  });

  // linkedin pixel no script
  useScriptFunction({
    id: "linkedin-pixel-noscript",
    value: linkedinPixelCode_noScript,
    noscript: true,
  });

  // twitter pixed
  useScriptFunction({
    id: "twitter-pixel",
    value: twitterPixelCode,
  });

  return null;
}
