import ArticlePageLayout from "@src/app/components/Layouts/ArticlePageLayout/ArticlePageLayout";
import { ResolvedCUEElement } from "@src/app/hooks/useArticle/elements";
import { ArticleCard, Author } from "@src/app/types/Article";
import { CUETags } from "@src/app/types/Opensearch";
import { type ReactElement, useEffect, useRef, useState } from "react";

import ContentLeft, { IContentLeft } from "./ContentLeft";
import ContentRight, { IContentRight } from "./ContentRight";
import ContentTop, { IContentTop } from "./ContentTop";

export interface IArticleContent {
  headline: string;
  date: string;
  authors: Author[];
  displayType: string;
  translators?: string;
  category?: string;
  mainVideoUrl?: string;
  nlIsDark?: boolean;
  nlIsThinkCulture?: boolean;
  nlHeaderTitle?: string;
  nlBodyText?: string;
  standfirst?: string;
  heroImgUrl?: string;
  heroImgCaption?: string | undefined;
  heroImgAlt?: string | undefined;
  heroImgWidth?: number;
  heroImgHeight?: number;
  bodyElements: ResolvedCUEElement[];
  mainMedia: ResolvedCUEElement[];
  tags: CUETags[];
  recommendedArticles?: ArticleCard[];
  recommendedArticlesIsLoading?: boolean;
  isArticleAComic?: boolean;
  isArticleVideo?: boolean;
  isArticleBigRead?: boolean;
  isArticlePhotoStory?: boolean;
  isOutbrainEnabled?: boolean;
  pathname: string;
  testId?: string;
  popularThisMonth?: ArticleCard[]; // This is already correct
}

export default function ArticleContent({
  headline,
  date,
  authors,
  translators,
  category,
  displayType,
  mainVideoUrl,
  nlIsDark,
  nlIsThinkCulture,
  nlHeaderTitle,
  nlBodyText,
  standfirst,
  heroImgUrl,
  heroImgCaption,
  heroImgAlt,
  heroImgWidth,
  heroImgHeight,
  mainMedia,
  bodyElements,
  tags,
  recommendedArticles,
  recommendedArticlesIsLoading,
  isArticleAComic,
  isArticleVideo,
  isArticleBigRead,
  isArticlePhotoStory,
  isOutbrainEnabled,
  pathname,
  testId = "article-content",
  popularThisMonth, // Ensure this is destructured here
}: IArticleContent): ReactElement {
  const contentLeftRef = useRef<HTMLDivElement | null>(null);
  const [contentLeftHeight, setContentLeftHeight] = useState<number>(0);

  // to retreive content left height for sticky container
  useEffect(() => {
    if (contentLeftRef.current) {
      const handleResize = () => {
        const outbrain = document.querySelector(".OUTBRAIN");
        const recommendedStories = document.querySelector(
          ".recommendedStories",
        );
        const outbrainHeight = outbrain?.getBoundingClientRect().height || 0;
        const recommendedStoriesHeight =
          recommendedStories?.getBoundingClientRect().height || 0;
        const contentLeftHeight = contentLeftRef.current?.offsetHeight || 0;

        setContentLeftHeight(
          contentLeftHeight - recommendedStoriesHeight - outbrainHeight,
        );
      };

      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(contentLeftRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const isFullWidthArticle =
    isArticleAComic || isArticleBigRead || isArticlePhotoStory;
  console.log("Popular this month:", popularThisMonth); // Add this to check the data

  const contentTopProps: IContentTop = {
    testId: `${testId}-top`,
    headline,
    isFullWidthArticle,
    displayType,
  };

  const contentLeftProps: IContentLeft = {
    testId: `${testId}-left`,
    authors,
    translators,
    nlIsDark,
    nlIsThinkCulture,
    nlHeaderTitle,
    nlBodyText,
    standfirst,
    heroImgUrl,
    heroImgCaption,
    heroImgAlt,
    heroImgWidth,
    heroImgHeight,
    mainMedia,
    bodyElements,
    category,
    mainVideoUrl,
    date,
    tags,
    recommendedArticles,
    recommendedArticlesIsLoading,
    isArticleAComic,
    isArticleVideo,
    isArticleBigRead,
    isArticlePhotoStory,
    isOutbrainEnabled,
    popularThisMonth, // Passed to ContentRight
    pathname,
  };

  const contentRightProps: IContentRight = {
    testId: `${testId}-right`,
    authors,
    translators,
    category,
    nlIsDark,
    nlIsThinkCulture,
    nlHeaderTitle,
    nlBodyText,
    popularThisMonth, // Passed to ContentRight
    contentLeftHeight,
  };

  return (
    <ArticlePageLayout
      testId={testId}
      contentTop={<ContentTop {...contentTopProps} />}
      contentLeft={
        <div ref={contentLeftRef}>
          <ContentLeft {...contentLeftProps} />
        </div>
      }
      contentRight={<ContentRight {...contentRightProps} />}
      isContentFullWidth={isFullWidthArticle}
    />
  );
}
