import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { faFacebookF, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InternalLink from "@src/app/components/Elements/Typography/InternalLink/InternalLink";
import { getWindowHref } from "@src/app/utils/helpers";
import cx from "classnames";
import type { ReactElement } from "react";

import { LinkProps, MISC_LIST } from "./constants";
import styles from "./Header.module.css";

export type Props = Readonly<{
  testId?: string;
}>;

export default function DesktopMiscLinks({
  testId = "desktop-misc-links",
}: Props): ReactElement {
  const sendDataToGTM = useGTMDispatch();

  return (
    <ul data-testid={`${testId}-navLinks`} className={cx(styles.navLinks)}>
      {MISC_LIST.map((listItem: LinkProps, index: number) => (
        <li
          key={index}
          data-testid={`${testId}-headerLinks`}
          className={cx(styles.headerLinks)}
        >
          <InternalLink
            to={listItem.url + "?ref=header-page"}
            onClick={
              listItem.title === "newsletter"
                ? () => {
                    sendDataToGTM({
                      event: "tcCustomEvent",
                      eventCategory: "newsletter",
                      eventLabel: getWindowHref(),
                      eventAction: "click",
                    });
                  }
                : undefined
            }
          >
            {listItem.title}
          </InternalLink>
        </li>
      ))}
      <li data-testid={`${testId}-divider-2`} className={cx(styles.divider)}>
        |
      </li>
      <li
        data-testid={`${testId}-facebookIcon`}
        className={cx(styles.facebookIcon)}
      >
        <a
          href="https://www.facebook.com/ThinkChina/?ref=header-page"
          aria-label="Visit ThinkChina Facebook Page"
          target="_blank"
          onClick={() => {
            sendDataToGTM({
              event: "tcCustomEvent",
              eventCategory: "social link",
              eventAction: "click",
              eventLabel: "facebook",
            });
          }}
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
      </li>

      <li
        data-testid={`${testId}-twitterIcon`}
        className={cx(styles.twitterIcon)}
      >
        <a
          href="https://twitter.com/ThinkChinaSG?ref=header-page"
          aria-label="Visit ThinkChina Twitter Page"
          target="_blank"
          onClick={() => {
            sendDataToGTM({
              event: "tcCustomEvent",
              eventCategory: "social link",
              eventAction: "click",
              eventLabel: "twitter",
            });
          }}
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faXTwitter} />
        </a>
      </li>
    </ul>
  );
}
