import SPHLogo from "@src/app/assets/logo@2x.svg";
import SPHMobileLogo from "@src/app/assets/logo@mobile.svg";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import useWindowDimensions from "@src/app/hooks/useWindowDimensions";
import cx from "classnames";
import type { ReactElement } from "react";

import DesktopMiscLinks from "./DesktopMiscLinks";
import DesktopQuickLinks from "./DesktopQuickLinks";
import styles from "./Header.module.css";
import NavMenu from "./NavMenu/NavMenu";
import Search from "./Search/Search";

export type LinkProps = Readonly<{
  title: string;
  url: string;
}>;

export type Props = Readonly<{
  testId?: string;
}>;

export default function Header({ testId = "header" }: Props): ReactElement {
  const { isTablet } = useWindowDimensions();

  console.log(isTablet, "isTablet");

  return (
    <div
      data-testid={`${testId}-outerContainer`}
      className={cx(styles.outerContainer)}
    >
      <div id="navbar-1">
        <MainContainer>
          <div
            data-testid={`${testId}-innerContainer`}
            className={cx(styles.innerContainer)}
          >
            <a
              href="/"
              data-testid={`${testId}-logo`}
              className={cx(styles.logo)}
              aria-label="Navigate to Home Page"
            >
              {isTablet ? (
                <img src={SPHLogo} alt="SPH Logo" />
              ) : (
                <img src={SPHMobileLogo} alt="SPH Mobile Logo" />
              )}
            </a>
            <div
              data-testid={`${testId}-actions`}
              className={cx(styles.actions)}
            >
              <div
                data-testid={`${testId}-mobileActions`}
                className={cx(styles.mobileActions)}
              >
                <Search />
                <NavMenu />
              </div>
              <DesktopMiscLinks testId={testId} />
            </div>
          </div>
        </MainContainer>
      </div>
      <DesktopQuickLinks testId={testId} />
    </div>
  );
}
