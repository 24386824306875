import { EDITORS_PICK_STORY_QUEUE_TYPE } from "@src/api/v1/storyQueue";
import ArticleCardContent from "@src/app/components/Blocks/ArticleCardContent/ArticleCardContent";
import Advertisement from "@src/app/components/Elements/Advertisement/Advertisement";
import { GoogleAdsSlotFactory } from "@src/app/components/Elements/Advertisement/helpers";
import BlockTitle from "@src/app/components/Elements/Typography/BlockTitle/BlockTitle";
import Column from "@src/app/components/Layouts/Column/Column";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import Row from "@src/app/components/Layouts/Row/Row";
import StandardCardLayout from "@src/app/components/Layouts/StandardCardLayout/StandardCardLayout";
import useStoryQueue from "@src/app/hooks/useStoryQueue";
import { AdvertisementTypeEnum } from "@src/app/types/Ads.d";
import { EditorPickArticle } from "src/app/hooks/useHomePageListing";
import { ArticleCard } from "src/app/types/Article";

import EditorsPick from "./EditorsPick";
import styles from "./EditorsPick.module.css";

type ArticleCardBlock = {
  articles: ArticleCard[];
};

type EditorPickArticleCards = {
  editorsPickArticle: EditorPickArticle;
} & ArticleCardBlock;

const ArticleCards = ({ articles }: { articles: ArticleCard[] }) => {
  return articles.map((article: ArticleCard) => {
    const {
      articleName,
      id,
      blurb,
      category,
      authors,
      imgUrl,
      imgWidth,
      imgHeight,
      displayType,
    } = article;

    const articlePath = `${article.articlePath}?ref=home-latest-articles`;

    return (
      <ArticleCardContent
        key={id}
        articlePath={articlePath}
        articleTitle={articleName}
        authors={authors}
        categoryLabelProps={{
          href: category.path,
          label: category.name,
        }}
        hasAuthorAndDate
        hasSidePadding
        hasBackground
        hasSnippet
        imgUrl={imgUrl}
        imgWidth={imgWidth}
        imgHeight={imgHeight}
        paragraphContent={blurb}
        testId="article-card-content-popular"
        isVideo={displayType == "video"}
        displayType={displayType}
      />
    );
  });
};

export default function EditorPickArticles({
  articles,
  editorsPickArticle,
}: EditorPickArticleCards) {
  const { displayTitle, articleCard: editorsPickArticleCard } =
    editorsPickArticle;

  // Assuming `useStoryQueue` returns a payload with the type `StoryQueuePayloadType`
  const { data: storyQueuePayload } = useStoryQueue({
    type: EDITORS_PICK_STORY_QUEUE_TYPE,
  });

  // Type `storyQueueArticles` and assign correctly
  const storyQueueArticles: ArticleCard | undefined =
    storyQueuePayload?.data.articles[0] || undefined;

  const displayedEditorsPickArticleCard: ArticleCard | undefined =
    storyQueueArticles || editorsPickArticleCard;

  const displayedEditorPickTitle: string =
    storyQueuePayload?.data.displayTitle || displayTitle;

  // The rest of your code remains the same
  const authorsData = displayedEditorsPickArticleCard?.authors || [];

  // Combine authors and translators data
  const authorImgUrls = authorsData.map(
    (author) => author.headshotImageUrl || "",
  );
  const authorNames = authorsData
    .map((author) => author.name)
    .filter(Boolean) as string[];
  const authorUrls = authorsData
    .map((author) => author.url)
    .filter(Boolean) as string[];

  const editorsPickData = {
    articleUrl: displayedEditorsPickArticleCard?.articlePath || "",
    authorImgUrls,
    authorNames,
    authorUrls,
    backgroundImg: displayedEditorsPickArticleCard?.imgUrl || "",
    backgroundImgAlt: "Feature Article Banner",
    categoryLabelProps: {
      href: displayedEditorsPickArticleCard?.category
        ? displayedEditorsPickArticleCard.category.path
        : "",
      label: displayedEditorsPickArticleCard?.category
        ? displayedEditorsPickArticleCard.category.name
        : "",
    },
    editorsPickTitle: displayedEditorPickTitle || "",
    headline: displayedEditorsPickArticleCard?.articleName || "",
    paragraphContent: displayedEditorsPickArticleCard?.blurb || "",
    translator: displayedEditorsPickArticleCard?.translator || "",
    testId: "editors-pick-content",
  };

  return (
    <>
      {displayedEditorsPickArticleCard && (
        <div className={styles.editorsPick}>
          <EditorsPick {...editorsPickData} />
        </div>
      )}

      <div className={styles.subArticles}>
        <MainContainer>
          <div className={styles.subArticleTitle}>
            <BlockTitle hasBorder>Latest Articles</BlockTitle>
          </div>

          <Row>
            <Column>
              <StandardCardLayout>
                <ArticleCards articles={articles.slice(0, 2)} />
                <Advertisement
                  adUnitProps={{
                    type: AdvertisementTypeEnum.IMU1,
                    slot: GoogleAdsSlotFactory.imu1("homepage"),
                  }}
                />
                <ArticleCards articles={articles.slice(2)} />
              </StandardCardLayout>
            </Column>
          </Row>
        </MainContainer>
      </div>
    </>
  );
}
