import IconMaximize from "@src/app/assets/images/icon-maximize.svg";
import IconMinimize from "@src/app/assets/images/icon-minimize.svg";
// import formatRawHtml from "@src/app/helper/formatRawHtml";
import cx from "classnames";
import type { MouseEvent, ReactElement, ReactNode } from "react";
import { useState } from "react";

import ResponsiveImage from "../../Elements/ResponsiveImage/ResponsiveImage";
import styles from "./PortraitImageLightBox.module.css";

type Props = Readonly<{
  src: string;
  alt: string | undefined;
  caption?: ReactNode;
  srcWidth: number;
  srcHeight: number;
  testId: string;
  isOffsetWidth?: boolean;
}>;

export default function PortraitImageLightBox({
  alt,
  src,
  caption,
  srcWidth,
  srcHeight,
  testId = "portrait-image-light-box-figcaption",
  isOffsetWidth = true,
}: Props): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [fadeOutAnimation, setIsFadeOutAnimtion] = useState(false);
  const isPortrait = srcHeight >= srcWidth;

  function onOpenModal(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
    setIsOpen(true);
  }
  function onModalClose(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    document.getElementsByTagName("html")[0].style.overflow = "auto";
    setIsFadeOutAnimtion(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsFadeOutAnimtion(false);
    }, 250);
  }
  return (
    <>
      <div
        data-testid={testId}
        className={cx(styles.portraitImageLightBox, {
          [styles.isOffsetWidth]: isOffsetWidth,
        })}
      >
        <figure className={cx({ [styles.innerWidth]: isPortrait })}>
          <div
            className={styles.imageWrapper}
            onClick={onOpenModal}
            role="presentation"
          >
            <button className={styles.portraitImageLightBoxExpandButton}>
              <img src={IconMaximize} alt="icon-maximize" />
            </button>
            <ResponsiveImage
              src={src}
              alt={alt}
              srcWidth={srcWidth || 581}
              srcHeight={srcHeight || 800}
              loading="lazy"
              displayWidth={581}
            />
          </div>

          <figcaption data-testid={testId}>
            {/* {formatRawHtml(caption ? caption : "")} */}
            {caption}
          </figcaption>
        </figure>
      </div>
      {isOpen && (
        <div
          data-testid={`${testId}-modal`}
          className={cx(
            styles.portraitImageLightBoxModal,
            fadeOutAnimation && styles.out,
          )}
        >
          <figure>
            <button
              onClick={onModalClose}
              className={styles.portraitImageLightBoxCloseButton}
            >
              <img src={IconMinimize} alt="icon-minimize" />
            </button>
            <ResponsiveImage
              src={src}
              alt={alt}
              srcWidth={srcWidth || 581}
              srcHeight={srcHeight || 800}
              loading="lazy"
              displayWidth={581}
            />
            <figcaption data-testid={testId}>
              {/* {formatRawHtml(caption ? caption : "")} */}
              {caption}
            </figcaption>
          </figure>
        </div>
      )}
    </>
  );
}
