// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-transitions.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// import plugins
// @ts-expect-error polyfill
import lgHash from "lightgallery/plugins/hash/lg-hash.umd.js";
// @ts-expect-error polyfill
import lgZoom from "lightgallery/plugins/zoom/lg-zoom.umd.js";
// @ts-expect-error polyfill
import LightGallery from "lightgallery/react/Lightgallery.umd.js";
import type { ReactElement } from "react";

import styles from "./ComicLightbox.module.css";
import LightGalleryItem from "./LightGalleryItem";
import ComicTextWrapper from "./TextWrapper";

export type Props = Readonly<{
  id: string;
  testId?: string;
  images: Array<{ src: string; alt: string; width: number; height: number }>;
}>;

export default function ComicLightbox({
  id = "comicLightbox",
  testId = "comic-lightbox",
  images,
}: Props): ReactElement {
  // const appendLabel = (element: string, label: string) => {
  //   // if <span> is not exist then append the <span> label
  //   if (
  //     document.querySelectorAll(element).length &&
  //     document.querySelectorAll(element)[0].childNodes.length < 1
  //   ) {
  //     document
  //       .querySelectorAll(element)[0]
  //       .insertAdjacentHTML("afterbegin", `<span>${label}</span>`);
  //   }
  // };

  const onInit = () => {
    document
      .querySelectorAll(".lg-container")[0]
      .setAttribute("data-testid", `${testId}-modal`);
  };

  // const onAfterOpen = () => {
  //   // append labels on toolbar action button
  //   appendLabel(".lg-toolbar .lg-zoom-in", "ZOOM IN");
  //   appendLabel(".lg-toolbar .lg-zoom-out", "ZOOM OUT");
  //   appendLabel(".lg-toolbar .lg-fullscreen", "FULL SCREEN");
  //   appendLabel(".lg-toolbar .lg-close", "CLOSE");
  // };

  return (
    <div id={id} className={styles.comicLightbox} data-testid={testId}>
      <ComicTextWrapper parentTestId={testId} />
      <LightGallery
        onInit={onInit}
        // onAfterOpen={onAfterOpen}
        plugins={[lgZoom, lgHash]}
        actualSize={false}
        counter={false}
        customSlideName={true}
        download={false}
        fullScreen={false}
        getCaptionFromTitleOrAlt={false}
        mobileSettings={{
          showCloseIcon: true,
          fullScreen: false,
        }}
        mode="lg-slide"
        showZoomInOutIcons={true}
        speed={300}
      >
        {images.map((item, index) => (
          <LightGalleryItem
            alt={item.alt}
            key={id + index}
            src={item.src}
            width={item.width}
            height={item.height}
            parentTestId={testId}
          />
        ))}
      </LightGallery>
    </div>
  );
}
