import {
  ResolvedCUEAnnotation,
  ResolvedCUEElement,
  ResolvedFieldValue,
} from "@src/app/hooks/useArticle/elements";
import { ReactNode } from "react";

import {
  CUEAnnotationType,
  defaultAnnotationComponents,
  DefaultDebug,
} from "./AnnotationItem";
import { nl2br } from "./utils";

type AnnotationProps = {
  element: ResolvedCUEElement | ResolvedFieldValue;
};

const AnnotationOrder = [
  CUEAnnotationType.ExternalLink as string,
  CUEAnnotationType.InternalLink as string,
];

const AnnotationWrapper = ({
  annotations,
  children,
}: {
  annotations: ResolvedCUEAnnotation[];
  children: ReactNode;
}) => {
  return annotations.reduce((children, node) => {
    const AnnotationComponent =
      defaultAnnotationComponents?.[node.name as CUEAnnotationType] ??
      DefaultDebug;

    const props =
      AnnotationComponent === DefaultDebug
        ? {
            "data-annotationname": node.name,
            "data-annotationvalue": JSON.stringify(node.value),
          }
        : typeof AnnotationComponent === "string"
          ? {}
          : {
              element: node.context,
            };

    return <AnnotationComponent {...props}>{children}</AnnotationComponent>;
  }, children);
};

const isEqualArray = (a: unknown[], b: unknown[]) =>
  a.length === b.length && a.every((d, index) => b.indexOf(d) === index);

export default function Annotation({ element }: AnnotationProps): ReactNode {
  const plainText = element.value || "";
  if (!element.annotations?.length) return nl2br(plainText);
  // support multibyte characters correctly
  let characterArray: string[];
  if (typeof Intl !== "undefined" && typeof Intl.Segmenter === "function") {
    // works for modifier like skin tones
    const segmenter = new Intl.Segmenter(); // default to granularity grapheme
    characterArray = [...segmenter.segment(plainText)].map((s) => s.segment);
  } else {
    // fallback, will not work for modifier chars
    characterArray = [...plainText];
  }

  const orderedAnnotations = [...element.annotations].sort(
    (a, b) =>
      // sort to enusure extenal_link and internal_link are applied first
      AnnotationOrder.indexOf(a.name) - AnnotationOrder.indexOf(b.name) ||
      // stable order for all types
      (a.name > b.name ? -1 : a.name < b.name ? 1 : 0),
  );

  const annotationsByIndex = orderedAnnotations.reduce<
    ResolvedCUEAnnotation[][]
  >((byIndex, annotation) => {
    const maxIndex = annotation.index + annotation.length;
    for (let i = annotation.index; i < maxIndex; i += 1) {
      if (!byIndex[i]) {
        byIndex[i] = [];
      }
      byIndex[i].push(annotation);
    }
    return byIndex;
  }, []);

  const textRangesWithAnnotations = annotationsByIndex.reduce<
    {
      start: number;
      end: number;
      annotations: ResolvedCUEAnnotation[];
    }[]
  >((ranges, annotations, index) => {
    const lastRange = ranges[ranges.length - 1];
    if (ranges.length && lastRange.end === index) {
      // last range ended at current index
      if (isEqualArray(lastRange.annotations, annotations)) {
        // extend last range by one char
        lastRange.end += 1;
      } else {
        // start new range with different annotations
        ranges.push({
          start: index,
          end: index + 1,
          annotations,
        });
      }
    } else {
      if (ranges.length) {
        // add text from last range until this index
        ranges.push({
          start: lastRange.end,
          end: index,
          annotations: [],
        });
      } else if (index > 0) {
        // add text from begining until first annotation index
        ranges.push({
          start: 0,
          end: index,
          annotations: [],
        });
      }
      // start new range with annotations
      ranges.push({
        start: index,
        end: index + 1,
        annotations,
      });
    }
    if (
      index === annotationsByIndex.length - 1 &&
      index + 1 < characterArray.length
    ) {
      // text from last annotation until end of plain text
      ranges.push({
        start: index + 1,
        end: characterArray.length,
        annotations: [],
      });
    }

    return ranges;
  }, []);

  return (
    <>
      {textRangesWithAnnotations.map((range, rangeIndex) => {
        const text = characterArray.slice(range.start, range.end).join("");

        if (range.annotations.length) {
          return (
            <AnnotationWrapper annotations={range.annotations} key={rangeIndex}>
              {nl2br(text)}
            </AnnotationWrapper>
          );
        }
        return nl2br(text);
      })}
    </>
  );
}
