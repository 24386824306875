import PopularReadCard from "@src/app/components/Blocks/PopularReadCard/PopularReadCard";
import { ArticleCard } from "@src/app/types/Article";
import type { ReactElement } from "react";

import styles from "./PopularReadCardList.module.css";

type Props = Readonly<{
  articles: ArticleCard[];
  testId?: string;
}>;

export default function PopularReadCardList({
  articles,
  testId,
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={styles.popularReadCardList}>
      <div className={styles.popularReadCardListHeader}>Popular This Month</div>
      {articles.map((article, index: number) => {
        const { imgUrl, articleName, articlePath, authors, category } = article;

        // Type guard to ensure category is of type Category
        const categoryLabelProps = {
          label: category.name || "",
          href: category.path || "",
        };

        return (
          <div key={index} className={styles.popularReadCardListItem}>
            <PopularReadCard
              imgUrl={imgUrl}
              articleTitle={articleName}
              articlePath={articlePath}
              authors={authors}
              categoryLabelProps={categoryLabelProps}
              testId={`popular-read-card-${index}`}
            />
          </div>
        );
      })}
    </div>
  );
}
