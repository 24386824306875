import cx from "classnames";
import type { ReactElement } from "react";

import styles from "./ArticleBlurb.module.css";

type Props = Readonly<{
  children: string;
  isFeatured?: boolean;
  testId?: string;
}>;

export default function ArticleBlurb({
  children,
  isFeatured,
  testId,
}: Props): ReactElement | null {
  if (!children) {
    return null;
  }

  return (
    <div
      data-testid={testId}
      className={cx(styles.articleBlurb, { [styles.isFeatured]: isFeatured })}
    >
      <h5>{children}</h5>
    </div>
  );
}
