import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import SwiperCore from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./RecommendedArticlesLayout.module.css";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination]);

export type Props = Readonly<{
  title?: string;
  testId?: string;
  children: ReactNode[];
}>;

export default function RecommendedArticlesLayout({
  title = "Recommended Stories",
  children,
  testId,
}: Props): ReactElement {
  const swiperRef = useRef<SwiperCore>();

  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);

  const childrenCount = children.length;

  const slidesPerView = childrenCount >= 4 ? 4 : 3;
  const slidesPerGroup = slidesPerView;

  const sliderSettings = {
    992: {
      slidesPerGroup,
      slidesPerView,
      spaceBetween: 40,
    },
  };

  const handleSlideChange = (swiper: SwiperCore) => {
    const currentIndex = swiper.activeIndex;
    const slidesCount = swiper.slides.length;
    const slidesPerView = swiper.params.slidesPerView as number;

    const lastVisibleIndex = slidesCount - slidesPerView;
    setIsFirstSlide(currentIndex === 0);
    setIsLastSlide(currentIndex >= lastVisibleIndex);
  };

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.update();
    }
  }, [slidesPerView, slidesPerGroup]);

  return (
    <div
      data-testid={`${testId}`}
      className={cx(styles.recommendedArticlesLayoutWrapper)}
    >
      <div className={cx(styles.recommendedArticlesLayoutHeader)}>
        <div className={cx(styles.recommendedArticlesLayoutTitle)}>
          <span className={cx(styles.title)}>{title}</span>
        </div>

        <div className={cx(styles.recommendedArticlesLayoutNavigation)}>
          <button
            onClick={() => swiperRef.current?.slidePrev()}
            className={cx(isFirstSlide && styles.disabled)}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            onClick={() => swiperRef.current?.slideNext()}
            className={cx(isLastSlide && styles.disabled)}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={12}
        slidesPerView={1}
        slidesPerGroup={1}
        pagination={{ clickable: true }}
        breakpoints={sliderSettings}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        onSlideChange={handleSlideChange}
      >
        {children.map((child, index) => (
          <SwiperSlide key={index} className={cx(styles.childItem)}>
            {child}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
