import { OG_SITE_NAME } from "@src/app/components/Blocks/MetaTags/Constants";
import MetaTags from "@src/app/components/Blocks/MetaTags/MetaTags";
import AdSettings from "@src/app/components/Elements/Advertisement/AdSettings";
import CatfishAd from "@src/app/components/Elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@src/app/components/Elements/Advertisement/helpers";
import PrestitialAd from "@src/app/components/Elements/Advertisement/PrestitialAd";
import GAData from "@src/app/components/Elements/GAData/GAData";
import EditorPickArticles from "@src/app/components/Modules/LandingPage/EditorsPick/EditorsPickArticles";
import HeroContent from "@src/app/components/Modules/LandingPage/HeroContent/HeroContent";
import PopularContentSectionOne from "@src/app/components/Modules/LandingPage/PopularContentSectionOne/PopularContentSectionOne";
import PopularContentSectionTwo from "@src/app/components/Modules/LandingPage/PopularContentSectionTwo/PopularContentSectionTwo";
import useGlobalAdSetting from "@src/app/hooks/useGlobalAdSetting";
import useHomePageListing from "@src/app/hooks/useHomePageListing";

export default function HomePage() {
  const targeting = [{ key: "page", value: "home" }];

  const { isPrestitialEnabled, topOverlayImpressions, isCatfishEnabled } =
    useGlobalAdSetting({
      targeting,
    });

  const {
    heroArticles = [],
    editorsPickArticle,
    latestArticles = [],
    popularThisMonthArticles = [],
  } = useHomePageListing();

  // Extract IDs of articles in heroBannerArticles
  const heroArticleIds = new Set(heroArticles.map((i) => i.id));
  const popularArticles = popularThisMonthArticles.slice(0, 5);

  // excluding those already shown in featured hero articles and editor's pick
  const latestArticlesList = latestArticles.filter(
    (i) =>
      !heroArticleIds.has(i.id) && editorsPickArticle?.articleCard?.id != i.id,
  );

  return (
    <>
      <AdSettings />

      <MetaTags
        title={OG_SITE_NAME}
        description="ThinkChina - Get the latest insights on China's political, economic, socio-cultural and technology developments, seen through the eyes of Asian thought leaders and journalists."
        slug="home"
        image="https://www.thinkchina.sg/assets/logo@2x.svg"
      />
      <GAData title="Homepage" level2="home" contenttype="index/listing page" />

      <PrestitialAd
        slot={GoogleAdsSlotFactory.prestitial("homepage")}
        isPrestitialEnabled={isPrestitialEnabled}
        topOverlayImpressions={topOverlayImpressions}
      />

      <main>
        {heroArticles.length > 0 && (
          <HeroContent
            articles={heroArticles}
            eventCategory="homepage banner "
          />
        )}

        {popularArticles.length > 0 && (
          <PopularContentSectionOne articles={popularArticles} />
        )}

        <PopularContentSectionTwo />

        {editorsPickArticle && (
          <EditorPickArticles
            articles={latestArticlesList.slice(0, 18)}
            editorsPickArticle={editorsPickArticle}
          />
        )}
      </main>

      <CatfishAd
        slot={GoogleAdsSlotFactory.catfish("homepage")}
        isCatfishEnabled={isCatfishEnabled}
      />
    </>
  );
}
