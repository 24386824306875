import NewsletterIconCulture from "@src/app/assets/images/newsletter-culture-icon.svg";
import NewsletterIcon from "@src/app/assets/images/newsletter-icon.svg";
import { OG_SITE_NAME } from "@src/app/components/Blocks/MetaTags/Constants";
import MetaTags from "@src/app/components/Blocks/MetaTags/MetaTags";
import AdSettings from "@src/app/components/Elements/Advertisement/AdSettings";
import CatfishAd from "@src/app/components/Elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@src/app/components/Elements/Advertisement/helpers";
import PrestitialAd from "@src/app/components/Elements/Advertisement/PrestitialAd";
import GAData from "@src/app/components/Elements/GAData/GAData";
import SectionTitle from "@src/app/components/Elements/Typography/SectionTitle/SectionTitle";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import NewsletterWidget from "@src/app/components/Modules/NewsletterPage/NewsletterWidget/NewsletterWidget";
import useGlobalAdSetting from "@src/app/hooks/useGlobalAdSetting";
import { removeLeadingSlash } from "@src/app/utils/helpers";
import { useLocation } from "react-router-dom";

import styles from "./Newsletter.module.css";

export default function NewsletterPage() {
  const { pathname } = useLocation();

  const { isPrestitialEnabled, topOverlayImpressions, isCatfishEnabled } =
    useGlobalAdSetting();

  const NEWSLETTER_PROPS = {
    headerTitle: "Get the ThinkChina weekly newsletter",
    bodyText: "Insights on China, right in your mailbox. Sign up now.",
    icon: NewsletterIcon,
  };
  const NEWSLETTER_CULTURE_PROPS = {
    isThinkCulture: true,
    headerTitle: "ThinkCulture Newsletter",
    bodyText:
      "Your fortnightly dose of Chinese history, culture, and cartoons. Sign up now.",
    icon: NewsletterIconCulture,
  };

  return (
    <>
      <AdSettings />

      <MetaTags
        title={`Newsletter | ${OG_SITE_NAME}`}
        description=""
        ogType=""
        slug={removeLeadingSlash(pathname)}
      />

      <GAData
        title="Newsletter"
        level2="newsletter"
        contenttype="index/listing page"
      />

      <PrestitialAd
        slot={GoogleAdsSlotFactory.prestitial()}
        isPrestitialEnabled={isPrestitialEnabled}
        topOverlayImpressions={topOverlayImpressions}
      />

      <MainContainer>
        <div className={styles.newsletterContainer}>
          <SectionTitle>Sign up for our newsletters</SectionTitle>
          <div className={styles.newsletterContent}>
            <NewsletterWidget {...NEWSLETTER_PROPS} />
            <NewsletterWidget {...NEWSLETTER_CULTURE_PROPS} />
          </div>
        </div>
      </MainContainer>

      <CatfishAd
        slot={GoogleAdsSlotFactory.catfish()}
        isCatfishEnabled={isCatfishEnabled}
      />
    </>
  );
}
