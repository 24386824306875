import ArticleCardContent from "@src/app/components/Blocks/ArticleCardContent/ArticleCardContent";
import HeroLayout from "@src/app/components/Layouts/HeroLayout/HeroLayout";
import { ArticleCard } from "@src/app/types/Article";

import styles from "./HeroContent.module.css";

export type ArticleCardBlock = {
  articles: ArticleCard[];
  testId?: string;
  eventCategory?: string;
};

export default function HeroContent({
  articles,
  testId,
  eventCategory,
}: ArticleCardBlock) {
  return (
    articles.length > 0 && (
      <div className={styles.heroContent} data-testid={testId}>
        <section>
          <HeroLayout>
            {articles.map((article: ArticleCard, index: number) => {
              const {
                articleName,
                id,
                blurb,
                category,
                authors,
                imgUrl,
                imgWidth,
                imgHeight,
                displayType,
              } = article;
              // Adding refcode for CTR tracking for hero-articles
              const articlePath = `${article.articlePath}?ref=top-hero`;
              return (
                <ArticleCardContent
                  key={id}
                  articlePath={articlePath}
                  articleTitle={articleName}
                  authors={authors}
                  categoryLabelProps={{
                    href: category.path,
                    label: category.name,
                  }}
                  hasAuthorAndDate
                  hasSidePadding={index === 0}
                  hasTitleMargin
                  imgUrl={imgUrl}
                  imgWidth={imgWidth}
                  imgHeight={imgHeight}
                  isHero
                  isBig={index === 0}
                  isShorten
                  hasSnippet={index === 0}
                  paragraphContent={blurb}
                  testId="article-card-content-hero-small"
                  isVideo={displayType == "video"}
                  displayType={displayType}
                  {...(eventCategory && {
                    eventCategory: eventCategory + (index + 1),
                  })}
                />
              );
            })}
          </HeroLayout>
        </section>
      </div>
    )
  );
}
