import type { ReactElement, ReactNode } from "react";

import styles from "./StickyContainer.module.css";

type Props = Readonly<{
  minHeight?: string;
  children: ReactNode;
  testId?: string;
}>;

export default function StickyContainer({
  minHeight,
  children,
  testId,
}: Props): ReactElement {
  return (
    <div
      data-testid={testId}
      className={styles.stickyContainer}
      style={{ minHeight: minHeight ? minHeight : "80vh" }}
    >
      {children}
    </div>
  );
}
