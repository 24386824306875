import ResponsiveImage from "@src/app/components/Elements/ResponsiveImage/ResponsiveImage";
// import formatRawHtml from "@src/app/helper/formatRawHtml";
import type { ReactElement, ReactNode } from "react";

import styles from "./ArticleImage.module.css";

type Props = Readonly<{
  src: string;
  alt: string | undefined;
  caption?: ReactNode;
  width?: number;
  height?: number;
}>;

export default function ArticleImage({
  alt,
  src,
  caption,
  width,
  height,
}: Props): ReactElement {
  return (
    <div className={styles.articleImage}>
      <figure>
        <ResponsiveImage
          src={src}
          alt={alt}
          srcWidth={width || 900}
          srcHeight={height || 600}
          loading="lazy"
          displayWidth={900}
        />

        <figcaption data-testid="article-image-figcaption">
          {/* {formatRawHtml(caption ? caption : "")} */}
          {caption}
        </figcaption>
      </figure>
    </div>
  );
}
