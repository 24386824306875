import Column from "@src/app/components/Layouts/Column/Column";
import MainContainer from "@src/app/components/Layouts/MainContainer/MainContainer";
import Row from "@src/app/components/Layouts/Row/Row";
import cx from "classnames";
import { ReactElement, ReactNode } from "react";

import styles from "./ArticlePageLayout.module.css";

export type Props = Readonly<{
  testId?: string;
  contentTop?: ReactNode;
  contentLeft?: ReactNode;
  contentRight?: ReactNode;
  isContentFullWidth?: boolean;
}>;

export default function ArticlePageLayout({
  contentTop,
  contentLeft,
  contentRight,
  testId = "article-page-layout",
  isContentFullWidth,
}: Props): ReactElement {
  return (
    <div data-testid={testId} className={cx(styles.articlePageLayoutWrapper)}>
      <article>
        <MainContainer>
          <Row>
            <Column testId={`${testId}-top`} className={cx(styles.contentTop)}>
              {contentTop}
            </Column>
          </Row>
          <Row>
            <Column
              testId={`${testId}-left`}
              className={cx(styles.contentLeft, "contentLeft", {
                [styles.fullWidth]: isContentFullWidth,
              })}
            >
              {contentLeft}
            </Column>

            {!isContentFullWidth && (
              <Column
                testId={`${testId}-right`}
                className={cx(styles.contentRight)}
              >
                {contentRight}
              </Column>
            )}
          </Row>
        </MainContainer>
      </article>
    </div>
  );
}
