import IconImage from "@src/app/assets/images/icon-image.svg";
import IconNews from "@src/app/assets/images/icon-news.svg";
import cx from "classnames";
import type { ReactElement } from "react";

import styles from "./ImageTag.module.css";

type DisplayType = {
  label?: string;
  icon?: string;
};

export type Props = Readonly<{
  type: string;
  redBackground?: boolean;
  alignCenter?: boolean;
  testId?: string;
}>;

const types: Record<string, DisplayType> = {
  big_read: { label: "big read", icon: IconNews },
  photo_story: { label: "photo story", icon: IconImage },
};

export default function ImageTag({
  type,
  redBackground = false,
  alignCenter = false,
  testId = "",
}: Props): ReactElement | null {
  const articleType = types[type];

  // Explicitly check for the existence of articleType
  if (typeof articleType === "undefined") {
    return null;
  }

  const { label, icon } = articleType;

  // Check if label or icon exist and return null if both are empty
  if (!label && !icon) {
    return null;
  }

  return (
    <div
      className={cx({
        [styles.imageTag]: articleType,
        [styles.red]: redBackground,
        [styles.center]: alignCenter,
      })}
      data-testid={testId}
    >
      <div
        className={styles.label}
        aria-label={`Explore articles in the category: ${label ?? ""}`}
      >
        {icon && <img className={styles.icon} src={icon} alt="" />}
        {label}
      </div>
    </div>
  );
}
