import nextbtn from "@src/app/assets/images/nextbtn.svg";
import previewbtn from "@src/app/assets/images/previewbtn.svg";
import { type ReactElement } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

// import { getQueryString } from "src/app/helper/storybook";
import styles from "./Pagination.module.css";
import PaginationButton from "./PaginationButton";

const NUMBER_OF_BUTTONS = 9;

type Props = Readonly<{
  totalPages: number;
  testId?: string;
  isUseQueryParams?: boolean;
}>;

export default function Pagination({
  isUseQueryParams,
  totalPages = 54,
  testId = "pagination",
}: Props): ReactElement {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const isStoryBook: boolean = testId === "storybook-pagination";

  const keyword = searchParams.get("keyword") ?? "";

  const params = keyword ? `keyword=${keyword}&` : "";

  // Retrieve params URLSearchParams if component is rendered from storybook iframe instead of direct from browser.
  // const pageParamsValue: string =
  //   (isStoryBook ? getQueryString().page : urlSearchParams.get("page")) || "0";

  //Retrieve the pathnameBeforePage
  const pathnameBeforePage = pathname.split("/page/")[0];

  //Retrieve the page number from pathname
  const pageParamsValue: string =
    searchParams.get("page") || pathname.split("/page/")[1] || "0";

  // Integer from 0 to totalPages
  const currentPageIndex: number = Number(pageParamsValue);
  const currentPageNumber: number = currentPageIndex + 1;
  const maxPageIndex: number = totalPages - 1;
  const midWayPoint: number = Math.floor(NUMBER_OF_BUTTONS / 2);

  // minPage number starts from 1 to the total number of pages minus the number of displayed buttons plus 1
  // const minPageNumber: number = Math.min(
  //   Math.max(currentPageNumber - midWayPoint, 1),
  //   totalPages - NUMBER_OF_BUTTONS + 1,
  // );

  // const pageNumbersToRender: number[] = Array.from(
  //   { length: NUMBER_OF_BUTTONS },
  //   (_, index: number) => {
  //     return minPageNumber + index;
  //   },
  // );

  // Calculate the minPageNumber and ensure that final min page is not less than 1
  const minPageNumber: number = Math.max(
    Math.min(
      currentPageNumber - midWayPoint,
      totalPages - NUMBER_OF_BUTTONS + 1,
    ),
    1,
  );

  // Calculate the maximum page number to display within the available range without exceeding totalPages.
  const maxPageNumber: number = Math.min(
    minPageNumber + NUMBER_OF_BUTTONS - 1,
    totalPages,
  );

  // Generate pageNumbersToRender within the range
  const pageNumbersToRender: number[] = Array.from(
    { length: maxPageNumber - minPageNumber + 1 },
    (_, index: number) => minPageNumber + index,
  );

  return (
    <>
      {totalPages > 1 && (
        <div data-testid={testId} className={styles.pager}>
          <ul
            data-testid={`${testId}-pager-wrapper`}
            className={styles.pagerWrapper}
          >
            <PaginationButton
              isInternalLink={isUseQueryParams}
              testId={`${testId}-pager-item-first-btn`}
              isStoryBook={isStoryBook}
              isDisabled={currentPageIndex < 1}
              // pageLink={`?page=${0}`}
              pageLink={
                isUseQueryParams
                  ? `${pathname}?${params}page=0`
                  : `${pathnameBeforePage}/page/0`
              }
              displayText={
                <>
                  <img
                    className={styles.icon}
                    src={previewbtn}
                    alt="Previous"
                  />
                  <span>First</span>
                </>
              }
            />
            <PaginationButton
              isInternalLink={isUseQueryParams}
              testId={`${testId}-pager-item-prev-btn`}
              isStoryBook={isStoryBook}
              isDisabled={currentPageIndex < 1}
              // pageLink={`?page=${currentPageNumber}`}
              pageLink={
                isUseQueryParams
                  ? `${pathname}?${params}page=${currentPageIndex - 1}`
                  : `${pathnameBeforePage}/page/${currentPageIndex - 1}`
              }
              displayText={
                <img className={styles.icon} src={previewbtn} alt="Previous" />
              }
            />
            {minPageNumber > 1 && (
              <PaginationButton
                testId={`${testId}-pager-item-ellipsis-first`}
                hasNoBorder
                displayText="..."
              />
            )}

            {pageNumbersToRender.map((pageNumber: number) => (
              <PaginationButton
                isInternalLink={isUseQueryParams}
                testId={`${testId}-pager-item`}
                isStoryBook={isStoryBook}
                isActive={pageNumber === currentPageNumber}
                key={pageNumber}
                // pageLink={`?page=${pageNumber - 1}`}
                pageLink={
                  isUseQueryParams
                    ? `${pathname}?${params}page=${pageNumber - 1}`
                    : `${pathnameBeforePage}/page/${pageNumber - 1}`
                }
                displayText={String(pageNumber)}
              />
            ))}

            {currentPageIndex < maxPageIndex - midWayPoint &&
              maxPageIndex > NUMBER_OF_BUTTONS && (
                <PaginationButton
                  testId={`${testId}-pager-item-ellipsis-last`}
                  isStoryBook={isStoryBook}
                  hasNoBorder
                  displayText="..."
                />
              )}

            <PaginationButton
              isInternalLink={isUseQueryParams}
              testId={`${testId}-pager-item-next-btn`}
              isStoryBook={isStoryBook}
              isDisabled={currentPageIndex == maxPageIndex}
              // pageLink={`?page=${currentPageNumber}`}
              pageLink={
                isUseQueryParams
                  ? `${pathname}?${params}page=${currentPageNumber}`
                  : `${pathnameBeforePage}/page/${currentPageNumber}`
              }
              displayText={
                <img className={styles.icon} src={nextbtn} alt="Next " />
              }
            />
            <PaginationButton
              isInternalLink={isUseQueryParams}
              testId={`${testId}-pager-item-last-btn`}
              isStoryBook={isStoryBook}
              isDisabled={currentPageIndex == maxPageIndex}
              // pageLink={`?page=${maxPageIndex}`}
              pageLink={
                isUseQueryParams
                  ? `${pathname}?${params}page=${maxPageIndex}`
                  : `${pathnameBeforePage}/page/${maxPageIndex}`
              }
              displayText={
                <>
                  <span>Last</span>
                  <img className={styles.icon} src={nextbtn} alt="Next" />
                </>
              }
            />
          </ul>
        </div>
      )}
    </>
  );
}
