import "@fortawesome/fontawesome-svg-core/styles.css";

import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTiktok,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { ReactElement } from "react";

import styles from "./Footer.module.css";

export default function SocialLinks(): ReactElement {
  return (
    <div className={styles.footerSocial}>
      <a
        href="https://www.facebook.com/ThinkChina/?ref=footer-page"
        className={cx(styles.faIcon, styles.textLinks)}
        aria-label="Visit ThinkChina on Facebook"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faFacebookF} />
      </a>

      <a
        href="https://twitter.com/ThinkChinaSG?ref=footer-page"
        className={cx(styles.faIcon, styles.textLinks)}
        aria-label="Visit ThinkChina on Twitter"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faXTwitter} />
      </a>

      <a
        href="https://www.instagram.com/thinkchina/?ref=footer-page"
        className={cx(styles.faIcon, styles.textLinks)}
        aria-label="Visit ThinkChina on instagram"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a
        href="https://www.tiktok.com/@thinkchinasg?ref=footer-page"
        className={cx(styles.faIcon, styles.textLinks)}
        aria-label="Visit ThinkChina on Tiktok"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faTiktok} />
      </a>
      <a
        href="https://www.youtube.com/channel/UCdcwZ9MQXgF8osno8GdMTTg?ref=footer-page"
        className={cx(styles.faIcon, styles.textLinks)}
        aria-label="Visit ThinkChina on Youtube"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faYoutube} />
      </a>

      <a
        href="https://www.linkedin.com/company/thinkchinasg/?ref=footer-page"
        className={cx(styles.faIcon, styles.textLinks)}
        aria-label="Visit ThinkChina on Linkedin"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedinIn} />
      </a>
    </div>
  );
}
